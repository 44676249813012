import * as React from 'react';
import { SelectInput, InputProps } from 'react-admin';

type Props = {
  source?: string;
} & Omit<InputProps, 'source'>;
const StatusInput = (props: Props) => {
  const statusOptions = [
    { id: 0, name: 'Pending' },
    { id: 1, name: 'Completed' },
    { id: 2, name: 'Cancelled' },
  ];

  return (
    <SelectInput
      {...props}
      choices={statusOptions.map((status: any) => ({
        id: status.id,
        name: status.name,
      }))}
      source="status"
    />
  );
};

StatusInput.defaultProps = {
  source: 'status',
};

//*****************End Conditional question input ********************************/
export { StatusInput };

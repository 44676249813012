import * as React from 'react';
import { BooleanFieldProps, BooleanField } from 'react-admin';

const ColoredBooleanFields = (props: BooleanFieldProps) =>
  props.record && props.source ? (
    props.record[props.source] ? (
      <span style={{ color: 'green' }}>
        <BooleanField {...props} />
      </span>
    ) : (
      <span style={{ color: 'red' }}>
        <BooleanField {...props} />
      </span>
    )
  ) : null;

ColoredBooleanFields.defaultProps = BooleanField.defaultProps;

export default ColoredBooleanFields;

import * as React from 'react';
import { InputGuesser, CreateGuesser } from '@api-platform/admin';
import { AutocompleteArrayInput, ReferenceArrayInput, ReferenceInput, SelectInput, number, minValue, maxValue } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { SurveyFormStep } from '../interfaces/surveyformstep';

const validatePosition = [number(), minValue(1), maxValue(1000)];

const validateSurveyName = (value: any) => {
  if (!value) {
    return 'Please select the survey name';
  }
};

const SurveyFormStepCreate = (props: SurveyFormStep) => {
  return (
    <CreateGuesser {...props}>
      <InputGuesser source="title" />
      <ReferenceInput label="Survey Category" reference="survey_forms" source="form" validate={validateSurveyName}>
        <SelectInput optionText="title" optionValue="@id" />
      </ReferenceInput>
      <RichTextInput source="description" />
      <InputGuesser source="position" validate={validatePosition} />
      <ReferenceArrayInput label="Survey Questions" reference="survey_form_questions" source="formQuestions">
        <AutocompleteArrayInput optionText="label" optionValue="@id" />
      </ReferenceArrayInput>
    </CreateGuesser>
  );
};

export default SurveyFormStepCreate;

import ThemeIcon from '@material-ui/icons/Palette';

import ThemeEdit from './ThemeEdit';
import ThemeList from './ThemeList';
import ThemeShow from './ThemeShow';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: ThemeList,
  show: ThemeShow,
  edit: ThemeEdit,
  icon: ThemeIcon,
};

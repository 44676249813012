import * as React from 'react';
import { Link } from '@material-ui/core';

const CustomUrlField = ({ record, source }: any) => {
  return (
    record[source] && (
      <Link href={record[source]} rel="noopener" target="_blank">
        Link
      </Link>
    )
  );
};

export default CustomUrlField;

import PageIcon from '@material-ui/icons/Description';

import PageCreate from './PageCreate';
import PageEdit from './PageEdit';
import PageList from './PageList';
import PageShow from './PageShow';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: PageList,
  edit: PageEdit,
  create: PageCreate,
  show: PageShow,
  icon: PageIcon,
};

import * as React from 'react';
import { ArrayInput, BooleanInput, NumberInput, SimpleFormIterator, TextInput, ReferenceInput, SelectInput, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, CheckboxGroupInput, RadioButtonGroupInput, AutocompleteInput, required, number, minValue, maxValue, minLength, maxLength } from 'react-admin';
import { InputGuesser, EditGuesser } from '@api-platform/admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import RichTextInput from 'ra-input-rich-text';
import { Box } from '@material-ui/core';

import { SurveyFormQuestion } from '../interfaces/surveyformquestion';

import { ConditionalQuestionInput } from './SurveyFormQuestionInput';
import { blankAnswerRows, dateFormats, dateTimes, demographicsTypes, fileTypes, formAnswersYesNo, profileFieldNames, questionTypes, trueFalse } from './dropdowndata';

export const styles: Styles<Theme, any> = {
  label: { display: 'inline-block', marginRight: '1rem', minWidth: 360, maxWidth: 600 },
  type: { display: 'inline-block', marginRight: '1rem', width: 360 },
  category: { display: 'inline-block', marginRight: '1rem', width: 360 },
  demographicsType: { display: 'inline-block', marginRight: '1rem', width: 360 },
  formSteps: { display: 'inline-block', marginRight: '1rem', minWidth: 360, maxWidth: 800 },
  position: { display: 'inline-block', width: 75, marginRight: '1rem' },
  ratingActivated: { display: 'inline-block', marginRight: '1rem', width: 150 },
  isActive: { display: 'inline-block', marginRight: '1rem', width: 150 },
  isUpdateProfile: { display: 'inline-block', marginRight: '1rem' },
  profileFieldName: { display: 'inline-block', marginRight: '1rem', width: 360 },
  value: { display: 'inline-block', marginRight: '1rem', width: 400 },
  rating: { display: 'inline-block', width: 75, marginRight: '1rem' },
  recommendation: { display: 'inline-block', width: 250, marginRight: '1rem' },
  placeholder: { display: 'inline-block', width: 360, marginRight: '1rem' },
  other: { display: 'inline-block', width: 120, marginRight: '1rem' },
  formQuestions: { display: 'inline-block', marginRight: '1rem', minWidth: 360, maxWidth: 600 },
  info: { minWidth: 360, maxWidth: 544 },
  formAnswersInner: { display: 'flex' },
  displayBlock: { display: 'flex', width: '100%', flexWrap: 'nowrap', float: 'left', marginRight: '1rem' },
  fileTypeAnswer: { display: 'flex', width: '100%', flexWrap: 'nowrap', float: 'left', marginRight: '1rem' },
  dateTimeTypeAnswer: { display: 'flex', width: '100%', flexWrap: 'nowrap', float: 'left', marginRight: '1rem' },
  maxSelection: { width: 150, marginRight: '1rem' },
  otherAnswerText: { width: 400, marginRight: '1rem' },
  hasOtherFormAnswer: { display: 'inline-block', width: 400, marginRight: '1rem' },
  errorMessage: { display: 'inline-block', width: 360, marginRight: '1rem' },
  typeErrorMessage: { display: 'inline-block', width: 360, marginRight: '1rem' },
};

const useStyles = makeStyles(styles);

const SurveyFormQuestionTitle = (props: any) => {
  return <span>Edit Survey Question {props.record ? `"${props.record.label}"` : ''}</span>;
};

const SurveyFormQuestionEditInputs = (props: SurveyFormQuestion) => {
  const classes = useStyles(props);
  const [surveyFormQuestionData, setSurveyFormQuestionData] = React.useState<any>({} as any);

  const handleTypeChange = (event: any) => {
    switch (event.target.value) {
      case 'choice-true-false':
        setSurveyFormQuestionData({ formAnswers: trueFalse });
        break;
      case 'choice-unique-expanded':
        setSurveyFormQuestionData({ formAnswers: formAnswersYesNo });
        break;
      case 'choice-multiple-expanded':
      case 'choice-multiple-collapsed':
      case 'multi-line-open-ended':
      case 'choice-unique-collapsed':
      case 'ranking':
      case 'matrix':
        setSurveyFormQuestionData({ formAnswers: blankAnswerRows });
        break;
    }
  };

  // React.useEffect(() => {
  //   console.log(surveyFormQuestionData);
  // }, [surveyFormQuestionData]);

  const validateRequired = [required()];
  const validateNumber = [number(), minValue(0), maxValue(1000)];
  const validateRequiredNumber = [required(), number(), minValue(0), maxValue(1000)];
  const Separator = () => <Box pt="1em" />;
  const handleDisableRemove = (record: any) => {
    return record && record.formAnswers && record.formAnswers.length < 3 ? true : false;
  };

  return (
    <EditGuesser title={<SurveyFormQuestionTitle />} {...props}>
      <SelectInput
        choices={questionTypes.map((questionType) => ({
          id: questionType.id,
          name: questionType.name,
        }))}
        formClassName={classes.type}
        label="Question Type"
        optionText="name"
        optionValue="id"
        source="type"
        validate={validateRequired}
        disabled
        fullWidth
        onChange={handleTypeChange}
      />
      <Separator />
      <InputGuesser label="Question" source="label" validate={[required(), minLength(10), maxLength(1500)]} fullWidth multiline />

      <ReferenceInput filterToQuery={(searchText) => ({ title: searchText })} formClassName={classes.category} label="Question Category" reference="categories" source="category" validate={validateRequired} fullWidth>
        <AutocompleteInput optionText="title" optionValue="@id" />
      </ReferenceInput>
      <SelectInput
        choices={demographicsTypes.map((demographicsType) => ({
          id: demographicsType.id,
          name: demographicsType.name,
        }))}
        formClassName={classes.demographicsType}
        label="Demographics Type"
        optionText="name"
        optionValue="id"
        source="demographicsType"
        validate={validateRequired}
        fullWidth
      />
      <Separator />

      <TextInput source="placeholder" validate={[minLength(10), maxLength(100)]} fullWidth />

      <Separator />

      <ReferenceArrayInput filterToQuery={(searchText: string) => ({ title: searchText })} label="Survey Steps" reference="survey_form_steps" source="formSteps" validate={validateRequired}>
        <AutocompleteArrayInput optionText="title" optionValue="@id" />
      </ReferenceArrayInput>
      <Separator />
      <NumberInput formClassName={classes.position} source="position" validate={validateNumber} />
      <Separator />
      <BooleanInput formClassName={classes.ratingActivated} label="Rating?" source="ratingActivated" />
      <BooleanInput formClassName={classes.isActive} label="Active?" source="isActive" />
      <BooleanInput formClassName={classes.isActive} label="Comment?" source="isComment" fullWidth />

      <FormDataConsumer>{({ formData, ...rest }) => (formData && (formData.type === 'choice-unique-expanded' || formData.type === 'choice-multiple-expanded' || formData.type === 'text') ? <BooleanInput label="Update Profile?" source="isUpdateProfile" {...rest} formClassName={classes.isUpdateProfile} helperText="Please create relevant answer options" /> : null)}</FormDataConsumer>
      <Separator />

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData && (formData.type === 'choice-unique-expanded' || formData.type === 'choice-multiple-expanded' || formData.type === 'text') && formData.isUpdateProfile ? (
            <SelectInput
              choices={profileFieldNames.map((profileFieldName) => ({
                id: profileFieldName.id,
                name: profileFieldName.name,
              }))}
              formClassName={classes.profileFieldName}
              label="Profile Field"
              source="profileFieldName"
              fullWidth
              {...rest}
            />
          ) : null
        }
      </FormDataConsumer>

      <RichTextInput label="Help Text" source="info" validate={[minLength(10), maxLength(1000)]} fullWidth />

      <FormDataConsumer>{({ formData, ...rest }) => (formData && formData.type === 'slider' ? <TextInput source="sliderLabelMin" {...rest} validate={validateRequired} /> : null)}</FormDataConsumer>
      <FormDataConsumer>{({ formData, ...rest }) => (formData && formData.type === 'slider' ? <TextInput source="sliderLabelMax" {...rest} validate={validateRequired} /> : null)}</FormDataConsumer>
      <FormDataConsumer className={classes.formAnswers} fullWidth>
        {({ formData, ...rest }) => {
          return formData && (formData.type === 'choice-multiple-expanded' || formData.type === 'choice-multiple-collapsed' || formData.type === 'multi-line-open-ended' || formData.type === 'choice-unique-collapsed' || formData.type === 'choice-unique-expanded' || formData.type === 'choice-true-false' || formData.type === 'ranking' || formData.type === 'matrix') ? (
            <ArrayInput key={`${formData.type}-${formData.subType ? formData.subType : 'no'}`} defaultValue={surveyFormQuestionData.formAnswers} label="Answers" source="formAnswers" {...rest}>
              <SimpleFormIterator classes={{ form: classes.formAnswersInner }} disableAdd={formData.type === 'choice-true-false' || (formData.type === 'choice-unique-expanded' && formData.subType) ? true : false} disableRemove={formData.type === 'choice-true-false' || (formData.type === 'choice-unique-expanded' && formData.subType) || handleDisableRemove(formData) ? true : false}>
                <TextInput className={classes.value} label={`${formData.type === 'choice-unique-expanded' || formData.type === 'choice-unique-collapsed' ? 'Enter an Answer' : 'Enter a row label'}`} source="value" validate={[required(), minLength(2), maxLength(250)]} fullWidth multiline />
                <TextInput className={classes.rating} label="Rating" source="rating" validate={validateNumber} />
                <NumberInput className={classes.position} label="Position" source="position" validate={validateRequiredNumber} />
                {formData.type !== 'matrix' && formData.type !== 'ranking' ? <ConditionalQuestionInput formClassName={classes.formQuestions} /> : null}
                <ReferenceInput formClassName={classes.recommendation} label="Recommendation" perPage={100} reference="recommendations" source="recommendation" allowEmpty>
                  <AutocompleteInput optionText="name" optionValue="@id" />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          ) : null;
        }}
      </FormDataConsumer>

      <FormDataConsumer className={classes.maxSelection} fullWidth>
        {({ formData, ...rest }) => {
          const choiceGenerator = (formAnswers: any): Array<any> => {
            const arr: Array<any> = [];
            arr.push({ id: '', name: 'Max Selection' });
            arr.push({ id: 0, name: 'All' });

            for (let i = 1; i < formAnswers.length; i++) {
              const obj: any = {};
              obj['id'] = i;
              obj['name'] = `${i}`;
              arr.push(obj);
            }

            return arr;
          };

          return formData && (formData.type === 'choice-multiple-expanded' || formData.type === 'choice-multiple-collapsed') ? <SelectInput choices={choiceGenerator(formData.formAnswers)} label="Max Selection" optionText="name" optionValue="id" source="maxSelection" {...rest} /> : null;
        }}
      </FormDataConsumer>
      <FormDataConsumer className={classes.hasOtherFormAnswer} fullWidth>
        {({ formData, ...rest }) => {
          return formData && (formData.type === 'choice-multiple-expanded' || formData.type === 'choice-multiple-collapsed' || formData.type === 'choice-unique-collapsed' || formData.type === 'choice-unique-expanded') && !formData.subType ? <BooleanInput label="Add an 'Other' Answer Option for Comments" source="hasOtherFormAnswer" {...rest} /> : null;
        }}
      </FormDataConsumer>

      <FormDataConsumer className={classes.hasOtherFormAnswer} fullWidth>
        {({ formData, ...rest }) => {
          return formData && (formData.type === 'choice-multiple-expanded' || formData.type === 'choice-multiple-collapsed' || formData.type === 'choice-unique-collapsed' || formData.type === 'choice-unique-expanded') && formData.hasOtherFormAnswer ? <TextInput source="otherAnswerText" {...rest} validate={[required(), minLength(5), maxLength(100)]} /> : null;
        }}
      </FormDataConsumer>

      {/* ################### Start File Upload type answer #################### */}
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData && formData.type === 'file-upload' ? (
            <>
              <CheckboxGroupInput
                choices={fileTypes.map((fileType) => ({
                  id: fileType.id,
                  name: fileType.name,
                }))}
                classes={{ root: classes.fileTypeAnswer }}
                label="File Allowed"
                source="fileTypeAnswer.allowedFiles"
                validate={validateRequired}
                {...rest}
              />
              <TextInput helperText="When an invalid file type is uploaded, display this error message." label="Validation Message" source="fileTypeAnswer.typeErrorMessage" validate={[required(), minLength(5), maxLength(250)]} {...rest} />
            </>
          ) : null
        }
      </FormDataConsumer>

      {/* ################### End File Upload type answer #################### */}

      {/* ################### Start Date/Time type answer #################### */}
      <FormDataConsumer fullWidth>
        {({ formData, ...rest }) =>
          formData && formData.type === 'date-time' ? (
            <>
              <CheckboxGroupInput
                choices={dateTimes.map((dateTime) => ({
                  id: dateTime.id,
                  name: dateTime.name,
                }))}
                classes={{ root: classes.dateTimeTypeAnswer }}
                label="Date Time"
                source="dateTimeTypeAnswer.dateTime"
                validate={validateRequired}
                {...rest}
                fullWidth
              />
              <RadioButtonGroupInput
                choices={dateFormats.map((dateFormat) => ({
                  id: dateFormat.id,
                  name: dateFormat.name,
                }))}
                label="Date Format"
                source="dateTimeTypeAnswer.dateFormat"
                validate={validateRequired}
                {...rest}
                fullWidth
              />
              <TextInput className={classes.errorMessage} helperText="When an invalid file type is uploaded, display this error message." label="Validation Message" source="dateTimeTypeAnswer.errorMessage" validate={[required(), minLength(5), maxLength(250)]} fullWidth {...rest} />
            </>
          ) : null
        }
      </FormDataConsumer>
    </EditGuesser>
  );
};

const generateKey = (pre: any) => {
  return `${pre}_${new Date().getTime()}`;
};

const SurveyFormQuestionEdit = (props: SurveyFormQuestion) => {
  return <SurveyFormQuestionEditInputs key={generateKey(props.type)} {...props} />;
};

export default SurveyFormQuestionEdit;

import { AuthProvider } from 'react-admin';
import jwtDecode from 'jwt-decode';
const SERVERURL = process.env.REACT_APP_API_SERVER;

type jwtToken = {
  name: string;
  exp: number;
};

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const request = new Request(`${SERVERURL}/admin/login_check`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return await fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(({ token }) => {
        localStorage.setItem('token', token);
      });
  },
  logout: () => {
    localStorage.removeItem('token');

    return Promise.resolve();
  },
  checkAuth: () => {
    try {
      if (
        !localStorage.getItem('token') ||
        new Date().getTime() / 1000 >
          // @ts-ignore
          jwtDecode<jwtToken>(localStorage.getItem('token'))?.exp
      ) {
        return Promise.reject();
      }

      return Promise.resolve();
    } catch (e) {
      return Promise.reject();
    }
  },
  checkError: (err) => {
    if ([401, 403].includes(err?.status || err?.response?.status)) {
      localStorage.removeItem('token');

      return Promise.reject();
    }

    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};

export default authProvider;

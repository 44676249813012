import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';

const DeploymentTypeList = (props: any) => (
  <ListGuesser {...props} exporter={false}>
    <FieldGuesser source="name" />
  </ListGuesser>
);

export default DeploymentTypeList;

import * as React from 'react';
import { useState } from 'react';
import { Button, Loading, useRefresh } from 'react-admin';
import PropTypes from 'prop-types';
const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT || '';

const IsRequiredQuestionButton = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const refresh = useRefresh();
  const toggleSurveyCompanyQuestionRequired = async () => {
    const { record } = props;
    setLoading(true);
    const request = new Request(`${ENTRYPOINT}/survey_companies/${record.originId}/required`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    });
    await fetch(request).then((response) => response.json());
    setLoading(false);
    refresh();

    if (!isLoading) {
      return <Loading />;
    }
  };

  return <Button disabled={isLoading} label="Question Required Status" size="small" onClick={toggleSurveyCompanyQuestionRequired} />;
};

IsRequiredQuestionButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default IsRequiredQuestionButton;

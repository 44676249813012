import * as React from 'react';
import { ShowGuesser, FieldGuesser } from '@api-platform/admin';
import { ReferenceField, ImageField, TextField } from 'react-admin';

const CompanyShow = (props: any) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel />
    <FieldGuesser source="slug" addLabel />
    <ImageField label="Logo" source="logoUrl" />
    <FieldGuesser source="videoType" addLabel />
    <FieldGuesser source="videoUploadedUrl" addLabel />
    <FieldGuesser source="videoEmbedded.host" addLabel />
    <FieldGuesser source="videoEmbedded.id" addLabel />
    <FieldGuesser source="ownerType" addLabel />
    <FieldGuesser source="about" addLabel />
    <FieldGuesser source="address.request" addLabel />
    <FieldGuesser source="website" addLabel />
    <FieldGuesser source="domains" addLabel />
    <FieldGuesser source="facebook" addLabel />
    <FieldGuesser source="twitter" addLabel />
    <FieldGuesser source="linkedin" addLabel />
    <FieldGuesser source="instagram" addLabel />
    <FieldGuesser source="dashboardUrl" addLabel />
    <FieldGuesser source="dashboardInsightUrl" addLabel />
    <FieldGuesser source="dashboardCompletionUrl" addLabel />
    <FieldGuesser source="dashboardQuestionUrl" addLabel />
    <FieldGuesser source="dashboardAssessmentUrl" addLabel />
    <FieldGuesser source="dashboardDisparityUrl" addLabel />
    <FieldGuesser source="dashboardLegalUrl" addLabel />
    <FieldGuesser source="dashboardPartnerUrl" addLabel />
    <FieldGuesser source="dashboardMatterUrl" addLabel />
    <FieldGuesser source="admins" addLabel />
    <ReferenceField label="Industry" reference="industries" source="industry">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="cities" addLabel />
    <FieldGuesser source="departments" addLabel />
    <FieldGuesser source="divisions" addLabel />
    <FieldGuesser source="positions" addLabel />
    <FieldGuesser source="divisionHeads" addLabel />
    <FieldGuesser source="pyramidHeads" addLabel />
    <FieldGuesser source="regions" addLabel />
    <ReferenceField label="Employees Range" reference="employees_ranges" source="employeesRange">
      <TextField source="name" />
    </ReferenceField>
    <FieldGuesser source="nbFollowers" addLabel />
    <FieldGuesser source="nbUnfollowers" addLabel />
    <FieldGuesser source="nbReviews" addLabel />
    <FieldGuesser source="diversityAcrossOrganizationAvg" addLabel />
    <FieldGuesser source="diversityInLeadershipAvg" addLabel />
    <FieldGuesser source="workLikeBalanceAvg" addLabel />
    <FieldGuesser source="overallInclusivenessAvg" addLabel />
    <FieldGuesser source="benefitsAndResourcesAvg" addLabel />
    <FieldGuesser source="hasSubscription" addLabel />
    <FieldGuesser source="featured" addLabel />
    <FieldGuesser source="validated" addLabel />
    <FieldGuesser source="isPostJobs" addLabel />
    <FieldGuesser source="completionPercentage" addLabel />
    <FieldGuesser source="foundedAt" addLabel />
  </ShowGuesser>
);

export default CompanyShow;

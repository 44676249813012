import BannerIcon from '@material-ui/icons/QueueOutlined';

import BannerCreate from './BannerCreate';
import BannerEdit from './BannerEdit';
import BannerList from './BannerList';
import BannerShow from './BannerShow';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: BannerList,
  show: BannerShow,
  edit: BannerEdit,
  create: BannerCreate,
  icon: BannerIcon,
};

import MediaImageIcon from '@material-ui/icons/Image';

import MediaImageCreate from './MediaImageCreate';
import MediaImageEdit from './MediaImageEdit';
import MediaImageList from './MediaImageList';
import MediaImageShow from './MediaImageShow';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: MediaImageList,
  create: MediaImageCreate,
  edit: MediaImageEdit,
  show: MediaImageShow,
  icon: MediaImageIcon,
};

const campaignsFormTypes = [
  { id: 'Popup', name: 'Popup' },
  { id: 'Form', name: 'Form' },
];

const campaignTypes = [
  { id: 'Company', name: 'Company' },
  { id: 'Strategic Partner', name: 'Strategic Partner' },
];

const retargetTypes = [
  { id: 'None', name: 'None' },
  { id: 'Not Started', name: 'Not Started' },
  { id: 'Not Submitted', name: 'Not Submitted' },
  { id: 'Everyone', name: 'Everyone' },
];

const channelTypes = [
  { id: 'EMAIL', name: 'EMAIL' },
  { id: 'SMS', name: 'SMS' },
];

const frequencyTypes = [
  { id: 'ONCE', name: 'ONCE' },
  { id: 'HOURLY', name: 'HOURLY' },
  { id: 'DAILY', name: 'DAILY' },
  { id: 'WEEKLY', name: 'WEEKLY' },
  { id: 'MONTHLY', name: 'MONTHLY' },
];

const scheduleStartTypes = [
  { id: 'Immediately', name: 'Immediately' },
  { id: 'Once', name: 'Once' },
  { id: 'Hourly', name: 'Hourly' },
  { id: 'Daily', name: 'Daily' },
  { id: 'Weekly', name: 'Weekly' },
  { id: 'Monthly', name: 'Monthly' },
];

const messageTypes = [
  { id: 'TRANSACTIONAL', name: 'TRANSACTIONAL' },
  { id: 'PROMOTIONAL', name: 'PROMOTIONAL' },
];

export { campaignsFormTypes, campaignTypes, retargetTypes, channelTypes, frequencyTypes, scheduleStartTypes, messageTypes };

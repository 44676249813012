import AdminIcon from '@material-ui/icons/SupervisorAccount';

import AdminCreate from './AdminCreate';
import AdminEdit from './AdminEdit';
import AdminList from './AdminList';
import AdminShow from './AdminShow';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: AdminList,
  edit: AdminEdit,
  create: AdminCreate,
  show: AdminShow,
  icon: AdminIcon,
};

import * as React from 'react';
import { InputGuesser } from '@api-platform/admin';
import RichTextInput from 'ra-input-rich-text';
import { Edit, SaveButton, Toolbar, SimpleForm, DeleteButton } from 'react-admin';

const CategoryTitle = (props: any) => {
  return <span>Category {props.record ? `"${props.record.title}"` : ''}</span>;
};

const UserEditToolbar = (props: any) => {
  const { hasRecords } = props;

  return (
    <Toolbar {...props}>
      <SaveButton />
      <div style={{ marginLeft: '100px' }} />
      {hasRecords ? <DeleteButton disabled /> : <DeleteButton disabled={false} mutationMode="pessimistic" />}
    </Toolbar>
  );
};

const CategoryEdit = (props: any) => {
  const categoryId = props.id;
  const [hasRecords, setHasRecords] = React.useState(false);
  const SERVERURL = process.env.REACT_APP_API_ENTRYPOINT;

  React.useEffect(() => {
    fetch(`${SERVERURL}/survey_form_question?categoryId=${categoryId}`)
      .then((response) => response.json())
      .then((data) => {
        setHasRecords(data.RecordExists);
      })
      .catch((error) => {
        console.error('Error checking category id', error);
      });
  }, [categoryId]);

  return (
    <Edit title={<CategoryTitle />} {...props}>
      <SimpleForm toolbar={<UserEditToolbar hasRecords={hasRecords} />}>
        <InputGuesser source="title" />
        <RichTextInput source="description" />
        <InputGuesser source="isActive" />
      </SimpleForm>
    </Edit>
  );
};

export default CategoryEdit;

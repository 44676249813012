import * as React from 'react';
import { FieldGuesser } from '@api-platform/admin';
import { ShowGuesser } from '@api-platform/admin';
import { ReferenceField, TextField } from 'react-admin';

import { SurveyCompanySurveyFormQuestion } from '../interfaces/surveycompanysurveyformquestion';

const SurveyCompanySurveyFormQuestionShow = (props: SurveyCompanySurveyFormQuestion) => (
  <ShowGuesser {...props}>
    <ReferenceField link={false} reference="survey_companies" source="surveyCompany">
      <TextField source="title" />
    </ReferenceField>
    <FieldGuesser source="questionTitle" addLabel />
    <ReferenceField link={false} reference="survey_form_questions" source="parentSurveyFormQuestion">
      <TextField source="title" />
    </ReferenceField>

    <FieldGuesser source="rank" addLabel />
    <FieldGuesser source="info" addLabel />
    <FieldGuesser source="level" addLabel />
    <FieldGuesser source="isRequired" addLabel />
    <FieldGuesser source="maxAnswerSelection" addLabel />
    {/* <FormDataConsumer source="typeRestriction" alwaysOn>
      {({ formData }) => (formData && formData?.questionType === '' ? <FieldGuesser source="typeRestriction" addLabel /> : null)}
    </FormDataConsumer> */}
    <FieldGuesser source="typeRestriction" addLabel />
    <FieldGuesser source="isVisible" addLabel />
    <FieldGuesser source="questionType" addLabel />
    <FieldGuesser source="totalAns" addLabel />
  </ShowGuesser>
);

export default SurveyCompanySurveyFormQuestionShow;

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { ReferenceField, TextField, DateField, ReferenceInput, AutocompleteInput, Filter } from 'react-admin';

import { StatusField } from './CampaignSchedulesField';

const CampaignScheduleFilters = (props: any) => (
  <Filter {...props}>
    <ReferenceInput filterToQuery={(searchText) => ({ campaignName: searchText })} label="Pinpoint Campaign" reference="pinpoint_campaigns" source="pinpointCampaign" alwaysOn resettable>
      <AutocompleteInput optionText="campaignName" />
    </ReferenceInput>
  </Filter>
);

const CampaignScheduleList = (props: any) => (
  <ListGuesser filters={<CampaignScheduleFilters />} {...props} exporter={false} sort={{ field: 'scheduledAt', order: 'DESC' }}>
    <ReferenceField label="Pinpoint Campaign" link={false} reference="pinpoint_campaigns" sortBy="pinpointCampaign.campaignName" source="pinpointCampaign">
      <TextField source="campaignName" />
    </ReferenceField>
    <DateField
      options={{
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }}
      source="scheduledAt"
      showTime
    />
    <StatusField source="status" />
    <FieldGuesser source="totalRecipients" />
  </ListGuesser>
);

export default CampaignScheduleList;

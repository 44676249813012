import BarChartIcon from '@material-ui/icons/BarChart';

import EoaResultsEdit from './EoaResultsEdit';
import EoaResultsList from './EoaResultsList';
import EoaResultsShow from './EoaResultsShow';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: EoaResultsList,
  show: EoaResultsShow,
  edit: EoaResultsEdit,
  icon: BarChartIcon,
};

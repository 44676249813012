import * as React from 'react';
import { useState } from 'react';
import { Button, Loading, useRefresh } from 'react-admin';
import PropTypes from 'prop-types';
const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT || '';

const ClearDataButton = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const { record } = props;
  const refresh = useRefresh();
  const clearMemberData = async () => {
    setLoading(true);
    const request = new Request(`${ENTRYPOINT}/pinpoint_campaign_users/${record.originId}/clear`, {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    });

    await fetch(request).then((response) => response.json());
    setLoading(false);
    refresh();

    if (!isLoading) {
      return <Loading />;
    }
  };

  return <Button disabled={isLoading || record.totalContriQues === 0 ? true : false} label="Clear" variant="contained" onClick={clearMemberData} />;
};

ClearDataButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default ClearDataButton;

import * as React from 'react';
import { InputGuesser, EditGuesser } from '@api-platform/admin';

import { EmployeesRange } from '../interfaces/employeesrange';

const EmployeesRangeEdit = (props: EmployeesRange) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="position" />
  </EditGuesser>
);

export default EmployeesRangeEdit;

import * as React from 'react';
import { EditGuesser, InputGuesser } from '@api-platform/admin';
import RichTextInput from 'ra-input-rich-text';
import { ReferenceInput, AutocompleteInput, required, RichTextField, NumberInput, minValue, maxValue } from 'react-admin';

import { CompanyRecommendation } from '../interfaces/company_recommendation';

const validateRequired = [required()];
const validateLevel = [minValue(1), maxValue(5)];

const CompanyRecommendationEdit = (props: CompanyRecommendation) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" disabled />
    <RichTextField label="Original Description" source="content" fullWidth />
    <RichTextInput label="Customized Description" source="description" fullWidth />
    <NumberInput max={5} min={1} source="level" validate={validateLevel} />
    <InputGuesser source="isSelected" disabled />
    <ReferenceInput label="Original Category" perPage={100} reference="categories" source="category" validate={validateRequired}>
      <AutocompleteInput optionText="title" optionValue="@id" disabled />
    </ReferenceInput>
    <ReferenceInput label="Customized Category" perPage={100} reference="categories" source="category_title" validate={validateRequired}>
      <AutocompleteInput optionText="title" optionValue="@id" />
    </ReferenceInput>
    <ReferenceInput label="Company" perPage={100} reference="companies" source="company" validate={validateRequired}>
      <AutocompleteInput optionText="name" optionValue="@id" disabled />
    </ReferenceInput>
    <ReferenceInput label="Original Recommendation" perPage={100} reference="recommendations" source="recommendation" validate={validateRequired}>
      <AutocompleteInput optionText="name" optionValue="@id" disabled />
    </ReferenceInput>
  </EditGuesser>
);

export default CompanyRecommendationEdit;

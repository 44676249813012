import * as React from 'react';
import { InputProps, SelectInput, SimpleFormIterator, FormDataConsumer, BooleanInput, TextField, ArrayInput, useRecordContext, TextInput, RichTextField, BooleanField } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

import { Category } from '../interfaces/category';

import iterationStyle from './iterationStyle';

export const styles: Styles<Theme, any> = {
  surveyFormQuestion: { display: 'inline-block', marginRight: '1rem', width: '35%' },
  label: { display: 'inline-block', marginRight: '1rem', width: '25em' },
  position: { marginRight: '1rem', minWidth: 60, maxWidth: 80 },
  isRequired: { display: 'inline-block', marginRight: '1rem', width: 160 },
  isVisible: { display: 'inline-block', marginRight: '1rem', width: 160 },
  info: { display: 'inline-block', marginRight: '1rem', marginLeft: '1rem', width: 300 },
  surveyCompanyQuestions: { display: 'flex' },
};
const useStyles = makeStyles(styles);
type Props = {
  source?: string;
} & Omit<InputProps, 'source'>;

const SurveyCompanyArrayInput = ({ ...rest }: Props) => {
  const classes = useStyles(rest);
  const record = useRecordContext(rest);
  const [categories, setCategories] = React.useState<Category[]>([]);

  React.useEffect(() => {
    if (record) {
      const initializedQuestions = record.categories;
      setCategories(initializedQuestions || []);
    }
  }, [record]);

  if (!record || categories.length === 0) return null;
  const typeRestrictio: Array<any> = [
    { id: 'text', name: 'Text' },
    { id: 'number', name: 'Number' },
    { id: 'percentage', name: 'Percentage' },
  ];

  return (
    <ArrayInput source="categories">
      <SimpleFormIterator disableAdd disableRemove disableReordering>
        <FormDataConsumer>
          {({ scopedFormData, getSource }) => {
            return (
              <div key={scopedFormData.id} style={{ width: '100%' }}>
                <div style={{ display: 'flex' }}>
                  <h1>{scopedFormData?.category?.title}</h1>
                  <TextInput className={classes.position} label="Position" source={getSource?.('position') as string} />
                </div>
                <ArrayInput label={false} source={getSource?.('surveyCompanySurveyFormQuestions') as string} fullWidth>
                  <SimpleFormIterator classes={{ form: classes.surveyCompanyQuestions }} disableAdd disableRemove disableReordering>
                    <FormDataConsumer>
                      {({ scopedFormData, getSource }) => {
                        const choiceGenerator = (scopedFormData: any): Array<any> => {
                          let arr: Array<any> = [
                            { id: '', name: 'Max Selection' },
                            { id: 0, name: 'All' },
                          ];

                          for (let i = 1; i < scopedFormData.totalAns; i++) {
                            const obj: { id: number | null; name: string | null } = { id: null, name: null };
                            obj.id = i;
                            obj.name = `${i}`;
                            arr = [...arr, obj];
                          }

                          return arr;
                        };

                        return scopedFormData && scopedFormData.surveyFormQuestion ? (
                          <div key={scopedFormData.id} style={iterationStyle(scopedFormData)}>
                            <TextField className={classes.label} source={getSource?.('questionTitle') as string} style={{ width: '40em', marginRight: '1rem' }} />
                            <BooleanInput className={classes.isRequired} label="Is Required?" source={getSource?.('isRequired') as string} />
                            <BooleanField className={classes.isVisible} label="Is Visible?" source={getSource?.('isVisible') as string} />
                            <TextInput label="Rank" source={getSource?.('rank') as string} />
                            {scopedFormData && (scopedFormData.questionType === 'choice-multiple-expanded' || scopedFormData.questionType === 'choice-multiple-collapsed') && <SelectInput choices={choiceGenerator(scopedFormData)} label="Max Selection" optionText="name" optionValue="id" source={getSource?.('maxAnswerSelection')} style={{ width: 60 }} />}
                            {scopedFormData && (scopedFormData.questionType === 'text' || scopedFormData.questionType === 'textarea') && <SelectInput choices={typeRestrictio} label="Select Restriction" optionText="name" optionValue="id" source={getSource?.('typeRestriction')} style={{ width: 60 }} />}
                            <RichTextField className={classes.label} source={getSource?.('info') as string} />
                          </div>
                        ) : null;
                      }}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </div>
            );
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

SurveyCompanyArrayInput.defaultProps = {
  addField: true,
  source: 'surveyCompanyQuestions',
  resource: 'categories',
};
export { SurveyCompanyArrayInput };

import * as React from 'react';
import { InputGuesser, EditGuesser } from '@api-platform/admin';
import { ReferenceInput, AutocompleteInput, SelectInput, Toolbar, DeleteButton, required, minLength, maxLength, minValue, maxValue, FormDataConsumer, TextInput } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import RichTextInput from 'ra-input-rich-text';
import { Typography, Box } from '@material-ui/core';

import { PinpointCampaign } from '../interfaces/pinpointcampaign';

import SubmitPinpointCampaignButton from './ConfirmationDialog';
import { CampaignsFormTypeInput, ChannelTypeInput, CampaignTypeInput, RetargetTypeInput, SmsMessagetypeInput, ScheduleFrequencyInput, ScheduleStartTypeInput } from './PinpointCampaignInputs';

const PinpointCampaignTitle = (props: any) => {
  return <span>Pinpoint Campaign {props.record ? `"${props.record.campaignName}"` : ''}</span>;
};

export const styles: Styles<Theme, any> = {
  campaignName: { display: 'inline-block', marginRight: '1rem', width: 360 },
  campaignId: { display: 'inline-block', marginRight: '1rem', width: 360 },
  deploymentId: { display: 'inline-block', marginRight: '1rem', width: 360 },
  segmentId: { display: 'inline-block', marginRight: '1rem', width: 360 },
  surveyCompany: { display: 'inline-block', marginRight: '1rem', width: 360 },
  department: { display: 'inline-block', marginRight: '1rem', width: 360 },
  city: { display: 'inline-block', marginRight: '1rem', width: 360 },
  formType: { display: 'inline-block', marginRight: '1rem', width: 360 },
  channelType: { display: 'inline-block', marginRight: '1rem', width: 360 },
  campaignType: { display: 'inline-block', marginRight: '1rem', width: 360 },
  retargetType: { display: 'inline-block', marginRight: '1rem', width: 360 },
  pinpointCampaign: { display: 'inline-block', marginRight: '1rem', width: 360 },
  quesCountFirst: { display: 'inline-block', marginRight: '1rem', width: 360 },
  quesCountAlternate: { display: 'inline-block', marginRight: '1rem', width: 360 },
  isLanding: { display: 'inline-block', float: 'left', marginRight: '1rem', width: 240 },
  mailAssociatedUser: { display: 'inline-block', float: 'left', marginRight: '1rem', width: 280 },
  isLanguageSelection: { display: 'inline-block', float: 'left', marginRight: '1rem', width: 240 },
  isPositionSelection: { display: 'inline-block', float: 'left', marginRight: '1rem', width: 240 },
  isDepartmentSelection: { display: 'inline-block', float: 'left', marginRight: '1rem', width: 240 },
  isBirthDateSelection: { display: 'inline-block', float: 'left', marginRight: '1rem', width: 240 },
  isEthnicitySelection: { display: 'inline-block', float: 'left', marginRight: '1rem', width: 240 },
  isRaceSelection: { display: 'inline-block', float: 'left', marginRight: '1rem', width: 240 },
  isGenderSelection: { display: 'inline-block', float: 'left', marginRight: '1rem', width: 240 },
  scheduleFrequency: { display: 'inline-block', marginRight: '1rem', width: 240 },
  confirmAuthority: { display: 'inline-block', float: 'left', marginRight: '1rem', width: 240 },
  scheduleStarttype: { display: 'inline-block', marginRight: '1rem', width: 240 },
  scheduleStarttime: { display: 'inline-block', marginRight: '1rem', width: 240 },
  scheduleEndtime: { display: 'inline-block', marginRight: '1rem', width: 240 },
  scheduleTimezone: { display: 'inline-block', marginRight: '1rem', width: 240 },
  smsSenderid: { display: 'inline-block', marginRight: '1rem', width: 360 },
  smsMessagetype: { display: 'inline-block', marginRight: '1rem', width: 360 },
  defaultToolbar: { flex: 1, display: 'flex', justifyContent: 'space-between' },
};
const useStyles = makeStyles(styles);

const PinpointCampaignToolbar = (props: any) => {
  const classes = useStyles(props);

  return (
    <Toolbar {...props} className={classes.defaultToolbar}>
      <SubmitPinpointCampaignButton />
      <DeleteButton />
    </Toolbar>
  );
};

const Separator = () => {
  return (
    <React.Fragment>
      <Box pt="1em" width="100%" />
    </React.Fragment>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  return (
    <Typography
      style={{
        marginBottom: 20,
        verticalAlign: 'middle',
        display: 'inline-flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        background: '#F0F0F0',
        borderColor: '#DDD',
        padding: '11px 10px 9px',
        width: '100%',
      }}
      variant="h6">
      <MoreHorizIcon />
      {label}
    </Typography>
  );
};

const PinpointCampaignEdit = (props: PinpointCampaign) => {
  const classes = useStyles(props);

  return (
    <EditGuesser mutationMode="pessimistic" title={<PinpointCampaignTitle />} toolbar={<PinpointCampaignToolbar />} {...props}>
      <InputGuesser formClassName={classes.campaignName} label="Name" source="campaignName" validate={[required(), minLength(3), maxLength(100)]} fullWidth />
      <InputGuesser formClassName={classes.deploymentId} label="Deployment Id" source="deploymentId" validate={[required(), minValue(0), maxValue(10000)]} fullWidth />

      <Separator />
      <ReferenceInput filterToQuery={(searchText: string) => ({ title: searchText })} formClassName={classes.surveyCompany} label="Survey" reference="survey_companies" source="surveyCompany" validate={[required()]} fullWidth>
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <CampaignsFormTypeInput formClassName={classes.formType} source="formType" fullWidth />

      <Separator />
      <ChannelTypeInput formClassName={classes.channelType} fullWidth />
      <CampaignTypeInput formClassName={classes.campaignType} fullWidth />
      <RetargetTypeInput formClassName={classes.retargetType} fullWidth />

      <Separator />
      <FormDataConsumer formClassName={classes.pinpointCampaign} fullWidth>
        {({ formData, ...rest }) =>
          formData && formData.retargetType !== 'None' ? (
            <ReferenceInput filterToQuery={(searchText: string) => ({ campaignName: searchText, retargetType: 'None' })} formClassName={classes.pinpointCampaign} label="Orignal Pinpoint Campaign" reference="pinpoint_campaigns" source="pinpointCampaign" validate={[required()]} fullWidth {...rest}>
              <AutocompleteInput optionText="campaignName" />
            </ReferenceInput>
          ) : null
        }
      </FormDataConsumer>

      <Separator />
      <SectionTitle label="Optional Selection" />

      <InputGuesser formClassName={classes.isLanding} label="Landing Page?" source="isLanding" fullWidth />
      <InputGuesser formClassName={classes.mailAssociatedUser} label="Send mail to associated user?" source="mailAssociatedUser" fullWidth />
      <InputGuesser formClassName={classes.isLanguageSelection} label="Language Selection?" source="isLanguageSelection" fullWidth />
      <InputGuesser formClassName={classes.isPositionSelection} label="Position Selection?" source="isPositionSelection" fullWidth />
      <InputGuesser formClassName={classes.isDepartmentSelection} label="Department Selection?" source="isDepartmentSelection" fullWidth />
      <InputGuesser formClassName={classes.isBirthDateSelection} label="Birth Date Selection?" source="isBirthDateSelection" fullWidth />
      <InputGuesser formClassName={classes.isEthnicitySelection} label="Ethnicity Selection?" source="isEthnicitySelection" fullWidth />
      <InputGuesser formClassName={classes.isRaceSelection} label="Race Selection?" source="isRaceSelection" fullWidth />
      <InputGuesser formClassName={classes.isGenderSelection} label="Gender Selection?" source="isGenderSelection" />
      <InputGuesser formClassName={classes.confirmAuthority} label="Confirm Authority?" source="confirmAuthority" />

      <Separator />
      <SectionTitle label="Schedule" />

      <ScheduleFrequencyInput formClassName={classes.scheduleFrequency} fullWidth />
      <ScheduleStartTypeInput formClassName={classes.scheduleStarttype} fullWidth />

      <InputGuesser formClassName={classes.scheduleStarttime} label="Start Time" source="scheduleStarttime" validate={[required()]} fullWidth />
      <InputGuesser formClassName={classes.scheduleEndtime} label="End Time" source="scheduleEndtime" fullWidth />
      <FormDataConsumer fullWidth>
        {({ formData, ...rest }) =>
          formData && formData.channelType === 'EMAIL' ? (
            <>
              <Separator />
              <SectionTitle label="Email" />
              <TextInput label="Display Name" source="emailFromName" style={{ display: 'inline-block', marginRight: '1rem', width: 360 }} {...rest} />
              <InputGuesser label="From Email" source="emailFrom" style={{ display: 'inline-block', marginRight: '1rem', width: 360 }} {...rest} />
              <InputGuesser label="Title" source="emailTitle" style={{ display: 'inline-block', marginRight: '1rem', width: 360 }} {...rest} />

              <ReferenceInput filterToQuery={(searchText: string) => ({ label: searchText })} label="Email Template" reference="emails" source="email" style={{ display: 'inline-block', marginRight: '1rem', width: 360 }} {...rest}>
                <SelectInput formClassName={classes.email} optionText="label" optionValue="@id" fullWidth />
              </ReferenceInput>

              <InputGuesser helperText="Separate multiple emails with comma" label="Test Email(s)" source="emailCC" fullWidth multiline {...rest} />
              <RichTextInput helperText="Use tokens {{Attributes.Name}}, {{Attributes.Id}}" label="HTML Body" source="emailHtmlbody" {...rest} />
              <InputGuesser label="Body" source="emailBody" fullWidth multiline {...rest} />
            </>
          ) : null
        }
      </FormDataConsumer>
      <FormDataConsumer fullWidth>
        {({ formData, ...rest }) =>
          formData && formData.channelType === 'SMS' ? (
            <>
              <Separator />
              <SectionTitle label="SMS" />
              <SmsMessagetypeInput className={classes.smsMessagetype} fullWidth {...rest} />
              <TextInput className={classes.smsSenderid} label="Sender Id" source="smsSenderid" fullWidth />
              <InputGuesser label="Body" source="smsBody" fullWidth multiline {...rest} />
              <InputGuesser helperText="Separate multiple phones with comma" label="Test Phone(s)" source="smsCC" fullWidth multiline {...rest} />
            </>
          ) : null
        }
      </FormDataConsumer>
    </EditGuesser>
  );
};

export default PinpointCampaignEdit;

import * as React from 'react';
import { InputProps, SimpleFormIterator, FormDataConsumer, BooleanInput, ArrayInput, useRecordContext, ReferenceInput, AutocompleteInput, required, TextInput, minLength, maxLength } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

import { SurveyCompanySurveyFormQuestion } from '../interfaces/surveycompanysurveyformquestion';

export const styles: Styles<Theme, any> = {
  token: { display: 'inline-block', marginRight: '1rem', width: '30%' },
  division: { display: 'inline-block', marginRight: '1rem', width: '20%' },
  replaceValue: { display: 'inline-block', marginRight: '1rem', width: '30%' },
};
const useStyles = makeStyles(styles);
type Props = {
  source?: string;
} & Omit<InputProps, 'source'>;

const SurveyCompanyArrayToken = ({ ...rest }: Props) => {
  const classes = useStyles(rest);
  const record = useRecordContext(rest);
  const [tokens, setTokens] = React.useState<SurveyCompanySurveyFormQuestion[]>([]);

  React.useEffect(() => {
    if (record) {
      const initializedTokens = record.surveyCompanyTokens?.map((item: SurveyCompanySurveyFormQuestion, index: number) => {
        item.rank = item.rank === 0 ? index * 5 : item.rank;

        return item;
      });

      setTokens(initializedTokens || []);
    }
  }, [record]);

  if (!record || (tokens.length === 0 && record.surveyCompanyTokens?.length > 0)) {
    return null;
  }

  return (
    <ArrayInput source="surveyCompanyTokens" value={tokens}>
      <SimpleFormIterator>
        <ReferenceInput filterToQuery={(searchText: string) => ({ name: searchText })} formClassName={classes.token} label="Token" perPage={100} reference="tokens" source="token" validate={[required()]}>
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer formClassName={classes.division}>{({ scopedFormData, getSource }) => scopedFormData?.token === '/api/tokens/2' && !scopedFormData?.replaceValue && <BooleanInput label="Division" source={getSource?.('division') as string} defaultValue />}</FormDataConsumer>
        <FormDataConsumer formClassName={classes.replaceValue}>{({ scopedFormData, getSource }) => (!scopedFormData?.division || scopedFormData?.token !== '/api/tokens/2') && <TextInput label="Values" source={getSource?.('replaceValue') as string} validate={[required(), minLength(2), maxLength(120)]} />}</FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

SurveyCompanyArrayToken.defaultProps = {
  addField: true,
  source: 'surveyCompanyTokens',
  resource: 'survey_companies',
};
export { SurveyCompanyArrayToken };

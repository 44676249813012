import * as React from 'react';
import { EditGuesser, InputGuesser } from '@api-platform/admin';
import { EoaScore } from '../interfaces/eoa_results';
import RichTextInput from 'ra-input-rich-text';

const EoaResultsEdit = (props: EoaScore) => (
  <EditGuesser {...props}>
    <InputGuesser source="surveyCompany.title" label="SurveyCompany" />
    <InputGuesser source="company.name" label="Company" />
    <InputGuesser source="visionStrategy" />
    <InputGuesser source="deiInfrastructure" label="DEI Infrastructure" />
    <InputGuesser source="pipelineSourcing" />
    <InputGuesser source="screeningBiasMitigation" />
    <InputGuesser source="interviewPractices" />
    <InputGuesser source="developmentAdvocacy" />
    <InputGuesser source="performanceEvaluation" />
    <InputGuesser source="equitableBenefits" />
    <InputGuesser source="payEquity" />
    <InputGuesser source="learningDevelopment" />
    <InputGuesser source="assessmentResearch" />
    <InputGuesser source="deiMarketingCommunications" label="DEI Marketing Communications" />
    <InputGuesser source="sustainability" />
    <InputGuesser source="socialResponsibility" />
    <InputGuesser source="supplierDiversity" />
    <InputGuesser source="leadershipPulse" />
    <InputGuesser source="transformationalReadiness" />
    <InputGuesser source="inclusiveHiring" />
    <InputGuesser source="talentEnrichment" />
    <InputGuesser source="cultureBelonging" />
    <InputGuesser source="communitySocialImpact" />
    <InputGuesser source="overall" />
    <RichTextInput label="Overall Recommendations" source="overallRecommendations" fullWidth />
    <RichTextInput label="Transformational Readiness Strengths" source="transformationalReadinessStrengths" fullWidth />
    <RichTextInput label="Transformational Readiness Recommendations" source="transformationalReadinessRecommendations" fullWidth />
    <RichTextInput label="Inclusive Hiring Strengths" source="inclusiveHiringStrengths" fullWidth />
    <RichTextInput label="Inclusive Hiring Recommendations" source="inclusiveHiringRecommendations" fullWidth />
    <RichTextInput label="Talent Enrichment Strengths" source="talentEnrichmentStrengths" fullWidth />
    <RichTextInput label="Talent Enrichment Recommendations" source="talentEnrichmentRecommendations" fullWidth />
    <RichTextInput label="Culture Of Belonging Strengths" source="cultureBelongingStrengths" fullWidth />
    <RichTextInput label="Culture Of Belonging Recommendations" source="cultureBelongingRecommendations" fullWidth />
    <RichTextInput label="Community And Social Impact Strengths" source="communitySocialImpactStrengths" fullWidth />
    <RichTextInput label="Community And Social Impact Recommendations" source="communitySocialImpactRecommendations" fullWidth />
  </EditGuesser>
);

export default EoaResultsEdit;

import { blue, common, green, lightBlue, lightGreen, orange, red } from '@material-ui/core/colors';
import { useTheme } from '@material-ui/core/styles';
import { Identifier } from 'react-admin';

import { SurveyCompanySurveyFormQuestion } from '../interfaces/surveycompanysurveyformquestion';

const rowStyle = (selectedRow?: Identifier) => (record: SurveyCompanySurveyFormQuestion) => {
  const theme = useTheme();
  let style = {};

  if (!record) {
    return style;
  }

  if (selectedRow && selectedRow === record['@id']) {
    style = {
      ...style,
      backgroundColor: theme.palette.action.selected,
    };
  }

  if (record.level === 0)
    return {
      ...style,
      borderLeftColor: common.black,
      backgroundColor: 'lightgray',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };

  if (record.level === 1)
    return {
      ...style,
      borderLeftColor: green[500],
      backgroundColor: lightGreen[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
      paddingLeft: 10,
      marginLeft: 5,
    };

  if (record.level === 2)
    return {
      ...style,
      borderLeftColor: orange[500],
      backgroundColor: 'cornsilk',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
      paddingLeft: 10,
      marginLeft: 10,
    };

  if (record.level === 3)
    return {
      ...style,
      borderLeftColor: red[500],
      backgroundColor: 'lightcoral',
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
      paddingLeft: 10,
      marginLeft: 15,
    };

  if (record.level === 4)
    return {
      ...style,
      borderLeftColor: blue[500],
      backgroundColor: lightBlue[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
      paddingLeft: 10,
      marginLeft: 20,
    };

  return style;
};

export default rowStyle;

import * as React from 'react';
import { InputGuesser } from '@api-platform/admin';
import { ReferenceInput, SelectInput, ArrayInput, TextInput, SimpleFormIterator, AutocompleteArrayInput, ReferenceArrayInput, AutocompleteInput, ImageField, ImageInput, required, CheckboxGroupInput } from 'react-admin';
import { CreateGuesser } from '@api-platform/admin';

import { Company } from '../interfaces/company';

const validateRequired = [required()];

const CompanyCreate = (props: Company) => {
  const [hasSubscription, setHasSubscription] = React.useState(false);

  const handleCheckboxChange = (event: any) => {
    setHasSubscription(event);
  };

  return (
    <CreateGuesser {...props}>
      <InputGuesser source="name" validate={validateRequired} />
      {/* <InputGuesser source="slug" /> */}
      <InputGuesser source="about" />
      <InputGuesser source="address" />
      <ReferenceInput label="Industry" perPage={100} reference="industries" source="industry" validate={validateRequired}>
        <AutocompleteInput optionText="name" optionValue="@id" />
      </ReferenceInput>
      <InputGuesser source="website" />
      <InputGuesser source="facebook" />
      <InputGuesser source="twitter" />
      <InputGuesser source="linkedin" />
      {/* <InputGuesser source="dashboardUrl" />
    <InputGuesser source="dashboardCompletionUrl" />
    <InputGuesser source="dashboardQuestionUrl" />
    <InputGuesser source="dashboardAssessmentUrl" />
    <InputGuesser source="dashboardDisparityUrl" />
    <InputGuesser source="dashboardPartnerUrl" />
    <InputGuesser source="dashboardMatterUrl" />
    <InputGuesser source="dashboardLegalUrl" />
    <InputGuesser source="dashboardInsightUrl" /> */}
      <InputGuesser source="foundedAt" />
      <InputGuesser source="ownerType" />
      <ImageInput accept="image/*" label="Cover" source="coverFile">
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput accept="image/*" label="Logo" source="logoFile">
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput accept="video/*" label="Video" source="videoUploadedFile">
        <ImageField source="src" title="title" />
      </ImageInput>
      {/* <InputGuesser source="diversityAcrossOrganizationAvg" />
    <InputGuesser source="diversityInLeadershipAvg" /> */}
      {/* <InputGuesser source="workLikeBalanceAvg" />
    <InputGuesser source="overallInclusivenessAvg" />
    <InputGuesser source="benefitsAndResourcesAvg" />
    <InputGuesser source="enpsAvg" /> */}
      <InputGuesser source="globalAverage" />
      {/* <InputGuesser source="nbFollowers" />
    <InputGuesser source="nbUnfollowers" />
    <InputGuesser source="nbReviews" /> */}
      <InputGuesser source="featured" />
      <InputGuesser source="hasSubscription" onChange={handleCheckboxChange} />
      {hasSubscription && (
        <ReferenceArrayInput label="Products" reference="products" source="products">
          <CheckboxGroupInput />
        </ReferenceArrayInput>
      )}
      <InputGuesser source="validated" />
      <InputGuesser source="eoaSurveyResults" />
      <ArrayInput label="Email Domains" source="domains">
        <SimpleFormIterator>
          <TextInput label="Value" source="value" />
        </SimpleFormIterator>
      </ArrayInput>
      {/* <InputGuesser source="isPaternityLeave" />
    <InputGuesser source="isMaternityLeave" />
    <InputGuesser source="isAdoptiveLeave" />
    <InputGuesser source="isPaidTimeOff" />
    <InputGuesser source="isDemographics" />
    <InputGuesser source="reportFrequency" />
    <InputGuesser source="reportGeneratedAt" />
    <InputGuesser source="isPostJobs" />
    <InputGuesser source="feedGeneratedAt" />
    <InputGuesser source="eeoReportAvg" />
    <InputGuesser source="awardsRecognitionAvg" /> */}
      <ReferenceInput label="Employee Range" reference="employees_ranges" source="employeesRange" validate={validateRequired}>
        <SelectInput optionText="name" optionValue="@id" />
      </ReferenceInput>
      <ReferenceArrayInput label="Departments" reference="departments" source="departments">
        <AutocompleteArrayInput optionText="name" optionValue="@id" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Region" reference="regions" source="regions">
        <AutocompleteArrayInput optionText="name" optionValue="@id" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="City" reference="cities" source="cities">
        <AutocompleteArrayInput optionText="name" optionValue="@id" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Positions" reference="positions" source="positions">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Divisions" reference="divisions" source="divisions">
        <AutocompleteArrayInput optionText="name" optionValue="@id" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Pyramid Heads" reference="pyramid_heads" source="pyramidHeads">
        <AutocompleteArrayInput optionText="name" optionValue="@id" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="DivisionHeads" reference="division_heads" source="divisionHeads">
        <AutocompleteArrayInput optionText="name" optionValue="@id" />
      </ReferenceArrayInput>
      <ArrayInput label="Information pages" source="informationPages">
        <SimpleFormIterator>
          <ReferenceArrayInput reference="company_information_groups" source="company_information_pages.informationGroups">
            <ReferenceArrayInput reference="company_information_questions" source="company_information_groups.informationQuestions">
              <TextInput label="Value" source="company_information_questions.value" />
            </ReferenceArrayInput>
          </ReferenceArrayInput>
        </SimpleFormIterator>
      </ArrayInput>
      <InputGuesser source="completionPercentage" />
    </CreateGuesser>
  );
};
export default CompanyCreate;

import * as React from 'react';
import { InputGuesser, CreateGuesser } from '@api-platform/admin';
import RichTextInput from 'ra-input-rich-text';
import { NumberInput, maxValue, minValue } from 'react-admin';

import { Recommendation } from '../interfaces/recommendation';

const validateLevel = [minValue(1), maxValue(5)];

const RecommendationCreate = (props: Recommendation) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <RichTextInput label="Description" source="description" fullWidth />
    <NumberInput max={5} min={1} source="level" validate={validateLevel} />
  </CreateGuesser>
);

export default RecommendationCreate;

import RecommendationIcon from '@material-ui/icons/AssignmentReturnedOutlined';

import RecommendationCreate from './RecommendationCreate';
import RecommendationEdit from './RecommendationEdit';
import RecommendationList from './RecommendationList';
import RecommendationShow from './RecommendationShow';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: RecommendationList,
  show: RecommendationShow,
  edit: RecommendationEdit,
  create: RecommendationCreate,
  icon: RecommendationIcon,
};

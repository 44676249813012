const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;

const convertDateToString = (value: any) => {
  if (!(value instanceof Date) || isNaN(value.getDate())) return '';
  const pad = '00';
  const yyyy = value.getFullYear().toString();
  const MM = (value.getMonth() + 1).toString();
  const dd = value.getDate().toString();

  return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};

const dateFormatter = (value: any) => {
  if (value == null || value === '') return '';

  if (value instanceof Date) return convertDateToString(value);

  if (dateFormatRegex.test(value)) return value;

  return convertDateToString(new Date(value));
};

export { dateFormatter };

import * as React from 'react';
import { CreateGuesser, InputGuesser } from '@api-platform/admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { DateInput, TextInput, required, ReferenceInput, AutocompleteInput, FormDataConsumer, ArrayInput, SimpleFormIterator, BooleanInput, SelectInput } from 'react-admin';
import { Box } from '@material-ui/core';

import { User } from '../interfaces/user';

import { EmployeeStatusInput, RoleInput, UserTypeInput } from './UserInputs';

export const styles: Styles<Theme, any> = {
  firstName: { display: 'inline-block', marginRight: '1rem', minWidth: 360, maxWidth: 600 },
  lastName: { display: 'inline-block', marginRight: '1rem', width: 360 },
  uniqueId: { display: 'inline-block', marginRight: '1rem', width: 360 },
  company: { display: 'inline-block', marginRight: '1rem', width: 360 },
  email: { display: 'inline-block', marginRight: '1rem', width: 360 },
  phone: { display: 'inline-block', marginRight: '1rem', width: 360 },
  birthDate: { display: 'inline-block', marginRight: '1rem', width: 360 },
  password: { display: 'inline-block', marginRight: '1rem', width: 360 },
  badge: { display: 'inline-block', marginRight: '1rem', width: 360 },
  nativeLanguage: { display: 'inline-block', marginRight: '1rem', width: 360 },
  employeeStatus: { display: 'inline-block', marginRight: '1rem', width: 360 },
  familyStatus: { display: 'inline-block', marginRight: '1rem', width: 360 },
  transgenderIdentification: { display: 'inline-block', marginRight: '1rem', width: 360 },
  educationLevel: { display: 'inline-block', marginRight: '1rem', width: 360 },
  differentlyAbled: { display: 'inline-block', marginRight: '1rem', width: 360 },
  differentlyAbledOther: { display: 'inline-block', marginRight: '1rem', maxWidth: 360 },
  gender: { display: 'inline-block', marginRight: '1rem', width: 360 },
  genderOther: { display: 'inline-block', marginRight: '1rem', maxWidth: 360 },
  race: { display: 'inline-block', marginRight: '1rem', width: 360 },
  raceOther: { display: 'inline-block', marginRight: '1rem', maxWidth: 360 },
  sexualOrientation: { display: 'inline-block', marginRight: '1rem', width: 360 },
  sexualOrientationOther: { display: 'inline-block', marginRight: '1rem', maxWidth: 360 },
  religion: { display: 'inline-block', marginRight: '1rem', width: 360 },
  religionOther: { display: 'inline-block', marginRight: '1rem', maxWidth: 360 },
  role: { display: 'inline-block', marginRight: '1rem', width: 360 },
  userType: { display: 'inline-block', marginRight: '1rem', width: 360 },
  enableWelcome: { display: 'inline-block', marginRight: '1rem', width: 360 },
  accessAdminPortal: { display: 'inline-block', marginRight: '1rem', width: 360 },

  arrInputRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  arrInputForm: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
  },
};

const useStyles = makeStyles(styles);

const Separator = () => {
  return (
    <React.Fragment>
      <Box pt="1em" width="100%" />
    </React.Fragment>
  );
};

const UserCreate = (props: User) => {
  const classes = useStyles(props);

  return (
    <CreateGuesser {...props}>
      <InputGuesser formClassName={classes.firstName} source="firstName" validate={[required()]} fullWidth />
      <InputGuesser formClassName={classes.lastName} source="lastName" validate={[required()]} fullWidth />
      <InputGuesser formClassName={classes.uniqueId} label="Unique Id" source="uniqueId" fullWidth />
      <ReferenceInput filterToQuery={(searchText) => ({ name: searchText })} formClassName={classes.company} label="Company" reference="companies" resource="users" source="company" fullWidth>
        <AutocompleteInput />
      </ReferenceInput>
      <Separator />
      <InputGuesser formClassName={classes.email} source="email" validate={[required()]} fullWidth />
      <InputGuesser formClassName={classes.phone} source="phone" fullWidth />
      <DateInput formClassName={classes.birthDate} source="birthDate" fullWidth />
      <Separator />
      <InputGuesser source="password" fullWidth />
      <Separator />

      <ReferenceInput formClassName={classes.familyStatus} label="Family Status" reference="family_statuses" resource="users" source="familyStatus" fullWidth>
        <SelectInput />
      </ReferenceInput>

      <ReferenceInput formClassName={classes.nativeLanguage} label="Native Language Status" reference="native_language_statuses" resource="users" source="nativeLanguageStatus" fullWidth>
        <SelectInput />
      </ReferenceInput>

      <EmployeeStatusInput formClassName={classes.employeeStatus} fullWidth />
      <Separator />

      <ReferenceInput formClassName={classes.transgenderIdentification} label="Transgender Identification" reference="transgender_statuses" resource="users" source="transgenderIdentification" fullWidth>
        <SelectInput />
      </ReferenceInput>

      <ReferenceInput formClassName={classes.differentlyAbled} label="Disability Status" reference="disability_statuses" resource="users" source="disabilityStatus" fullWidth>
        <SelectInput />
      </ReferenceInput>

      <FormDataConsumer formClassName={classes.disabilityStatusOther} fullWidth>
        {({ formData, ...rest }) => (formData && formData.disabilityStatusOther === 'self-describe' ? <InputGuesser resource="users" source="disabilityStatusOther" {...rest} /> : null)}
      </FormDataConsumer>
      <Separator />

      <ReferenceInput formClassName={classes.gender} label="Gender" reference="genders" resource="users" source="gender" fullWidth>
        <SelectInput />
      </ReferenceInput>

      <FormDataConsumer formClassName={classes.genderOther} fullWidth>
        {({ formData, ...rest }) => (formData && formData.gender === '/api/genders/4' ? <InputGuesser resource="users" source="genderOther" {...rest} /> : null)}
      </FormDataConsumer>

      <ReferenceInput formClassName={classes.race} label="Race" reference="races" resource="users" source="race" fullWidth>
        <SelectInput />
      </ReferenceInput>

      <FormDataConsumer formClassName={classes.raceOther} fullWidth>
        {({ formData, ...rest }) => (formData && formData.race === '/api/races/10' ? <InputGuesser resource="users" source="raceOther" {...rest} /> : null)}
      </FormDataConsumer>
      <Separator />

      <ReferenceInput formClassName={classes.sexualOrientation} label="Sexual Orientation" reference="sexual_orientations" resource="users" source="sexualOrientation" fullWidth>
        <SelectInput />
      </ReferenceInput>

      <FormDataConsumer formClassName={classes.sexualOrientationOther} fullWidth>
        {({ formData, ...rest }) => (formData && formData.sexualOrientation === '/api/sexual_orientations/8' ? <InputGuesser resource="users" source="sexualOrientationOther" {...rest} /> : null)}
      </FormDataConsumer>

      <ReferenceInput formClassName={classes.familyStatus} label="Religion" reference="religions" resource="users" source="religion" fullWidth>
        <SelectInput />
      </ReferenceInput>

      <FormDataConsumer formClassName={classes.religionOther} fullWidth>
        {({ formData, ...rest }) => (formData && formData.religion === '/api/religions/9' ? <InputGuesser resource="users" source="religionOther" {...rest} validate={[required()]} /> : null)}
      </FormDataConsumer>
      <Separator />

      <ArrayInput source="workExperiences">
        <SimpleFormIterator classes={{ root: classes.arrInputRoot, form: classes.arrInputForm }}>
          <TextInput label="Title" source="title" fullWidth />
          <ReferenceInput filterToQuery={(searchText) => ({ name: searchText })} formClassName={classes.company} label="Company" reference="companies" resource="users" source="company" fullWidth>
            <AutocompleteInput />
          </ReferenceInput>
          <ReferenceInput label="Location" reference="cities" source="city" fullWidth>
            <AutocompleteInput />
          </ReferenceInput>
          <DateInput label="From Date" source="dateFrom" fullWidth />
          <BooleanInput label="Work On Going?" source="workOnGoing" fullWidth />
          <DateInput label="To Date" source="dateTo" fullWidth />
        </SimpleFormIterator>
      </ArrayInput>

      <Separator />
      <RoleInput formClassName={classes.role} fullWidth />
      <UserTypeInput formClassName={classes.userType} fullWidth />
      <BooleanInput defaultValue={false} formClassName={classes.enableWelcome} resource="users" source="enable" fullWidth />
      <BooleanInput defaultValue={false} formClassName={classes.enableWelcome} resource="users" source="enableWelcome" fullWidth />
      <BooleanInput formClassName={classes.accessAdminPortal} resource="users" source="accessAdminPortal" fullWidth />
    </CreateGuesser>
  );
};

export default UserCreate;

import * as React from 'react';
import { InputGuesser, CreateGuesser } from '@api-platform/admin';
import { ImageInput, ImageField, required, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const MediaImageCreate = (props: any) => (
  <CreateGuesser {...props}>
    <InputGuesser label="Image Alt Text" source="imageAlt" />
    <ImageInput accept="image/*" label="Image" source="imageFile" validate={[required()]}>
      <ImageField source="src" title="title" />
    </ImageInput>
    <RichTextInput label="Login Top Text" source="loginTextTop" />
    <RichTextInput label="Login Bottom Text" source="loginTextBottom" />
    <ReferenceArrayInput filterToQuery={(searchText: string) => ({ campaignName: searchText })} label="Pinpoint Campaigns" reference="pinpoint_campaigns" source="pinpointCampaigns">
      <AutocompleteArrayInput optionText="campaignName" optionValue="@id" />
    </ReferenceArrayInput>
  </CreateGuesser>
);
export default MediaImageCreate;

import * as React from 'react';
import { ShowGuesser } from '@api-platform/admin';
import { BooleanField, ReferenceArrayField, SingleFieldList, TextField } from 'react-admin';

import { Banner } from '../interfaces/banner';

const BannerShow = (props: Banner) => (
  <ShowGuesser {...props} exporter={false}>
    <TextField source="content" addLabel />
    <TextField source="link" addLabel />
    <BooleanField source="global" />
    <ReferenceArrayField label="Company" reference="companies" source="companies">
      <SingleFieldList linkType={false}>
        <TextField addLabel={false} source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
    <ReferenceArrayField label="Products" reference="products" source="products">
      <SingleFieldList linkType={false}>
        <TextField addLabel={false} source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
  </ShowGuesser>
);

export default BannerShow;

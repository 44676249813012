import * as React from 'react';
import { FieldGuesser, ShowGuesser } from '@api-platform/admin';
import { ReferenceField, RichTextField, TextField } from 'react-admin';

import { CompanyRecommendation } from '../interfaces/company_recommendation';

const CompanyRecommendationShow = (props: CompanyRecommendation) => (
  <ShowGuesser {...props} exporter={false}>
    <FieldGuesser source="name" />
    <RichTextField source="description" />
    <FieldGuesser source="level" />
    <ReferenceField label="Company" link={false} reference="companies" source="company">
      <TextField source="name" />
    </ReferenceField>
  </ShowGuesser>
);

export default CompanyRecommendationShow;

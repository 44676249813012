import * as React from 'react';
import { ShowGuesser } from '@api-platform/admin';
import { ReferenceField, TextField, ReferenceArrayField, SingleFieldList, ChipField, ArrayField, Datagrid, useRecordContext, SimpleShowLayout, NumberField, RichTextField, BooleanField } from 'react-admin';

import { SurveyFormQuestion } from '../interfaces/surveyformquestion';

import { QuestionTypeField, FileTypeField, DateTimeTypeField } from './SurveyFormQuestionField';
const SurveyFormQuestionTitle = () => {
  const record = useRecordContext();

  if (!record) return null;

  return <span>Question {record.label}</span>;
};

const SurveyFormQuestionShowLayout = () => {
  const record = useRecordContext();

  if (!record) return null;

  return (
    <SimpleShowLayout>
      <QuestionTypeField label="Question Type" />
      <BooleanField label="Rating?" source="ratingActivated" />
      <TextField source="label" addLabel />
      <RichTextField source="info" addLabel />
      {record.placeholder !== null && <TextField source="placeholder" addLabel />}
      {record.type === 'slider' && <NumberField source="sliderLabelMin" addLabel />}
      {record.type === 'slider' && <NumberField source="sliderLabelMax" addLabel />}
      <NumberField source="position" addLabel />
      <TextField source="demographicsType" addLabel />
      <ReferenceField label="Question Category" link={false} reference="categories" source="category">
        <TextField source="title" />
      </ReferenceField>
      {record.formAnswers.length > 0 && (record.type === 'choice-multiple-expanded' || record.type === 'choice-multiple-collapsed') && <TextField source="maxSelection" addLabel />}
      {record.formAnswers.length > 0 && (
        <ArrayField source="formAnswers">
          <Datagrid>
            <TextField source="value" />
            <TextField source="position" />
            <ReferenceArrayField label="Conditional Question(s)" reference="survey_form_questions" source="formQuestions">
              <SingleFieldList linkType={false}>
                <TextField addLabel={false} source="label" />
              </SingleFieldList>
            </ReferenceArrayField>
            {/* <ReferenceArrayField label="Recommendation" reference="recommendations" source="recommendation">
            <SingleFieldList linkType={false}>
                <TextField addLabel={false} source="name" />
              </SingleFieldList>
            </ReferenceArrayField> */}
          </Datagrid>
        </ArrayField>
      )}

      {record.formAnswers.length > 0 && record.otherAnswerText && <TextField source="otherAnswerText" addLabel />}
      {record.formAnswers.length > 0 && record.subType && <TextField source="subType" addLabel />}
      {record.profileFieldName !== null && <TextField source="profileFieldName" addLabel />}

      {record.fileTypeAnswer && <FileTypeField label="File Allowed" />}
      {record.fileTypeAnswer && <TextField label="Error Message" source="fileTypeAnswer.typeErrorMessage" />}

      {record.dateTimeTypeAnswer && <TextField label="Date Format" source="dateTimeTypeAnswer.dateFormat" addLabel />}
      {record.dateTimeTypeAnswer && <DateTimeTypeField />}
      {record.dateTimeTypeAnswer && <TextField label="Error Message" source="dateTimeTypeAnswer.errorMessage" addLabel />}

      <BooleanField label="Active?" source="isActive" />
      <BooleanField label="Update Profile?" source="isUpdateProfile" />
      <BooleanField label="Comment?" source="isComment" />

      <ReferenceArrayField label="Survey Category" reference="survey_form_steps" source="formSteps">
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleShowLayout>
  );
};

const SurveyFormQuestionShow = (props: SurveyFormQuestion) => (
  <ShowGuesser title={<SurveyFormQuestionTitle />} {...props}>
    <SurveyFormQuestionShowLayout />
  </ShowGuesser>
);

export default SurveyFormQuestionShow;

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { ImageField, Filter, SearchInput, ReferenceArrayField, SingleFieldList, ChipField } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
export const styles: Styles<Theme, any> = {
  image: { maxHeight: '3rem' },
};
const useStyles = makeStyles(styles);

const MediaImageFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Image Alt Text" source="imageAlt" alwaysOn resettable />
  </Filter>
);
const MediaImageList = (props: any) => {
  const classes = useStyles(props);

  return (
    <ListGuesser filters={<MediaImageFilter />} sort={{ field: 'updatedAt', order: 'DESC' }} {...props} exporter={false}>
      <FieldGuesser label="Image Alt Text" source="imageAlt" />
      <ImageField classes={{ image: classes.image }} label="Image" source="imageUrl" />
      <ReferenceArrayField label="Pinpoint Campaigns" reference="pinpoint_campaigns" source="pinpointCampaigns">
        <SingleFieldList>
          <ChipField source="campaignName" />
        </SingleFieldList>
      </ReferenceArrayField>
    </ListGuesser>
  );
};

export default MediaImageList;

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { ReferenceField, TextField, Filter, SearchInput, useRecordContext } from 'react-admin';

import { SurveyFormStep } from '../interfaces/surveyformstep';

const SurveyFormStepFilters = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Title" source="title" alwaysOn resettable />
  </Filter>
);

const SurveyFormStepCount = (props: any) => {
  const surveyFormStep = useRecordContext(props);

  return <span>{surveyFormStep?.formQuestions?.length}</span>;
};
SurveyFormStepCount.defaultProps = { label: 'Questions' };

const SurveyFormStepList = (props: SurveyFormStep) => (
  <ListGuesser filters={<SurveyFormStepFilters />} {...props} exporter={false} sort={{ field: 'id', order: 'DESC' }}>
    <FieldGuesser source="title" />
    <ReferenceField label="Survey Category" link={false} reference="survey_forms" sortBy="form.title" source="form">
      <TextField source="title" />
    </ReferenceField>
    <SurveyFormStepCount />
  </ListGuesser>
);
export default SurveyFormStepList;

import SurveyCompanyIcon from '@material-ui/icons/ReceiptTwoTone';

import SurveyCompanySurveyFormQuestionList from './SurveyCompanySurveyFormQuestionList';
import SurveyCompanySurveyFormQuestionEdit from './SurveyFormStepSurveyFormQuestionEdit';
import SurveyCompanySurveyFormQuestionShow from './SurveyCompanySurveyFormQuestionShow';
export default {
  list: SurveyCompanySurveyFormQuestionList,
  edit: SurveyCompanySurveyFormQuestionEdit,
  show: SurveyCompanySurveyFormQuestionShow,
  icon: SurveyCompanyIcon,
};

import * as React from 'react';
import { InputGuesser, EditGuesser } from '@api-platform/admin';
import { ReferenceField, TextField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
const SurveyCompanySurveyFormQuestionEdit = (props: any) => {
  return (
    <EditGuesser {...props}>
      <ReferenceField link={false} reference="survey_companies" source="surveyCompany">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField link={false} reference="survey_form_questions" source="surveyFormQuestion">
        <TextField source="label" />
      </ReferenceField>
      <ReferenceField link={false} reference="survey_form_questions" source="parentSurveyFormQuestion">
        <TextField source="label" />
      </ReferenceField>
      <RichTextInput source="info" />
      <InputGuesser source="isRequired" />
      <InputGuesser source="isVisible" />
    </EditGuesser>
  );
};

export default SurveyCompanySurveyFormQuestionEdit;

import * as React from 'react';
import { useTranslate, SelectInput, InputProps, CheckboxGroupInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

import { questionTypes, demographicsTypes, profileFieldNames, fileTypes } from './dropdowndata';

type Props = {
  source?: string;
} & Omit<InputProps, 'source'>;
const QuestionTypeInput = (props: Props) => {
  return (
    <SelectInput
      {...props}
      choices={questionTypes.map((questionType) => ({
        id: questionType.id,
        name: questionType.name,
      }))}
      source="type"
    />
  );
};

QuestionTypeInput.defaultProps = {
  source: 'type',
};
/***################################ */
const QuestionDemographicsTypeInput = ({ addField, ...rest }: Props) => {
  return (
    <SelectInput
      choices={demographicsTypes.map((demographicsType) => ({
        id: demographicsType.id,
        name: demographicsType.name,
      }))}
      source="demographicsType"
      {...rest}
    />
  );
};
QuestionDemographicsTypeInput.defaultProps = {
  addField: true,
  source: 'demographicsType',
  resource: 'survey_form_questions',
};
/**############################################################### */
/***################################ */
const QuestionProfileFieldNameInput = ({ addField, ...rest }: Props) => {
  const translate = useTranslate();

  return (
    <SelectInput
      choices={profileFieldNames.map((profileFieldName) => ({
        id: profileFieldName.id,
        name: translate(profileFieldName.name),
      }))}
      source="profileFieldName"
      {...rest}
    />
  );
};
QuestionProfileFieldNameInput.defaultProps = {
  addField: true,
  source: 'profileFieldName',
  resource: 'survey_form_questions',
};
/**############################################################### */

const FileTypeInput = ({ addField, ...rest }: Props) => {
  const translate = useTranslate();

  return (
    <CheckboxGroupInput
      choices={fileTypes.map((fileType) => ({
        id: fileType.id,
        name: translate(fileType.name),
      }))}
      source="fileTypeAnswer.allowedFiles"
      {...rest}
    />
  );
};

FileTypeInput.defaultProps = {
  addField: true,
  source: 'fileTypeAnswer.allowedFiles',
  resource: 'survey_form_questions',
};

//*****************Conditional question input ************************************/
const ConditionalQuestionInput = ({ ...rest }: Props) => {
  return rest && !rest?.record?.other ? (
    <ReferenceArrayInput filterToQuery={(searchText: string) => ({ label: searchText })} label="Conditional Questions" reference="survey_form_questions" source="formQuestions" {...rest} fullWidth>
      <AutocompleteArrayInput label="Conditional Questions" optionText="label" optionValue="@id" />
    </ReferenceArrayInput>
  ) : null;
};

ConditionalQuestionInput.defaultProps = {
  source: 'formQuestions',
  resource: 'survey_form_questions',
};

//*****************End Conditional question input ********************************/
export { QuestionTypeInput, FileTypeInput, QuestionProfileFieldNameInput, QuestionDemographicsTypeInput, ConditionalQuestionInput };

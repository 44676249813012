import * as React from 'react';
import { ShowGuesser, FieldGuesser } from '@api-platform/admin';
import { ReferenceField, TextField, FunctionField, useRecordContext, SimpleShowLayout } from 'react-admin';

import { User } from '../interfaces/user';

import { OauthProviderField, UserTypeField, RoleField } from './UserFields';

const UserTitle = () => {
  const record: User = useRecordContext();

  if (!record) return null;

  return (
    <span>
      User {record.firstName} {record.lastName}
    </span>
  );
};

const UserShowLayout = (props: User) => {
  const record: User = useRecordContext();

  if (!record) return null;

  return (
    <SimpleShowLayout {...props}>
      <UserTypeField />
      <FunctionField label="Name" render={(user: any) => `${user?.firstName} ${user?.lastName}`} />
      {record.uniqueId !== null && <FieldGuesser source="uniqueId" addLabel />}
      {record.company !== null && (
        <ReferenceField reference="companies" source="company">
          <TextField source="name" />
        </ReferenceField>
      )}
      <FieldGuesser source="email" addLabel />
      {record.oauthProvider !== null && <OauthProviderField />}
      {record.oauthUid !== null && <FieldGuesser source="oauthUid" addLabel />}
      {record.oauthAccessToken !== null && <FieldGuesser source="oauthAccessToken" addLabel />}
      {record.phone !== null && <FieldGuesser source="phone" addLabel />}
      {record.birthDate !== null && <FieldGuesser source="birthDate" addLabel />}
      <FieldGuesser source="nbReviews" addLabel />

      {record.nativeLanguageStatus !== null && (
        <ReferenceField reference="native_language_statuses" source="nativeLanguageStatus">
          <TextField source="name" />
        </ReferenceField>
      )}
      {record.familyStatus !== null && (
        <ReferenceField reference="family_statuses" source="familyStatus">
          <TextField source="name" />
        </ReferenceField>
      )}

      {record.disabilityStatus !== null && (
        <ReferenceField reference="disability_statuses" source="disabilityStatus">
          <TextField source="name" />
        </ReferenceField>
      )}
      {record.disabilityStatusOther !== null && <FieldGuesser source="disabilityStatusOther" addLabel />}
      {record.gender !== null && (
        <ReferenceField reference="genders" source="gender">
          <TextField source="name" />
        </ReferenceField>
      )}
      {record.genderOther !== null && <FieldGuesser source="genderOther" addLabel />}
      {record.race !== null && (
        <ReferenceField reference="races" source="race">
          <TextField source="name" />
        </ReferenceField>
      )}
      {record.raceOther !== null && <FieldGuesser source="raceOther" addLabel />}
      {record.religion !== null && (
        <ReferenceField reference="religions" source="religion">
          <TextField source="name" />
        </ReferenceField>
      )}
      {record.religionOther !== null && <FieldGuesser source="religionOther" addLabel />}
      {record.sexualOrientation !== null && (
        <ReferenceField reference="sexual_orientations" source="sexualOrientation">
          <TextField source="name" />
        </ReferenceField>
      )}
      {record.sexualOrientationOther !== null && <FieldGuesser source="sexualOrientationOther" addLabel />}
      {record.educationLevel !== null && (
        <ReferenceField reference="education_levels" source="educationLevel">
          <TextField source="name" />
        </ReferenceField>
      )}
      {record.transgenderStatus !== null && (
        <ReferenceField reference="transgender_statuses" source="transgenderStatus">
          <TextField source="name" />
        </ReferenceField>
      )}
      <FieldGuesser source="enable" addLabel />
      {record.role !== null && <RoleField />}
      {record.enableWelcome !== null && <FieldGuesser source="enableWelcome" addLabel />}
      <FieldGuesser source="accessAdminPortal" addLabel />
      <FieldGuesser source="createdAt" addLabel />
      <FieldGuesser source="lastLoginAt" addLabel />
      <FieldGuesser source="updatedAt" addLabel />
    </SimpleShowLayout>
  );
};

const UserShow = (props: User) => (
  <ShowGuesser title={<UserTitle />} {...props}>
    <UserShowLayout />
  </ShowGuesser>
);

export default UserShow;

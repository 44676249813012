import CompanyRecommendationIcon from '@material-ui/icons/QueueOutlined';

import CompanyRecommendationCreate from './CompanyRecommendationCreate';
import CompanyRecommendationEdit from './CompanyRecommendationEdit';
import CompanyRecommendationList from './CompanyRecommendationList';
import CompanyRecommendationShow from './CompanyRecommendationShow';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: CompanyRecommendationList,
  show: CompanyRecommendationShow,
  edit: CompanyRecommendationEdit,
  create: CompanyRecommendationCreate,
  icon: CompanyRecommendationIcon,
};

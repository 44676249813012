import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { Button, CardActions, CircularProgress, TextField, Avatar, Card, InputAdornment, IconButton } from '@material-ui/core';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useTranslate, useLogin, useNotify, Notification } from 'react-admin';
// import { Login as BaseLogin } from "react-admin";

import { kanarysTheme } from './themes';

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '1px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundImage: 'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    width: '100%',
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
}));

const renderInput = ({ meta: { touched, error } = { touched: false, error: undefined }, input: { ...inputProps }, ...props }) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />;

type FormValues = {
  username?: string;
  password?: string;
};

const { Form } = withTypes<FormValues>();

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation<{ nextPathname: string } | null>();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : '/').catch((error: Error) => {
      setLoading(false);
      notify(typeof error === 'string' ? error : typeof error === 'undefined' || !error.message ? 'ra.auth.sign_in_error' : error.message, {
        type: 'warning',
        messageArgs: {
          _: typeof error === 'string' ? error : error && error.message ? error.message : undefined,
        },
      });
    });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const validate = (values: FormValues) => {
    const errors: FormValues = {};

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }

    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }

    return errors;
  };

  return (
    // <BaseLogin {...props}>

    // </BaseLogin>
    <Form
      initialValues={{ username: '', password: '' }}
      render={({ handleSubmit }) => (
        <form noValidate onSubmit={handleSubmit}>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.hint}>Kanarys Admin</div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    disabled={loading}
                    id="username"
                    label={translate('ra.auth.username')}
                    autoFocus
                    name="username"
                    // @ts-ignore
                    component={renderInput}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    autoComplete="current-password"
                    disabled={loading}
                    id="password"
                    label={translate('ra.auth.password')}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    // @ts-ignore
                    component={renderInput}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button color="primary" disabled={loading} type="submit" variant="contained" fullWidth>
                  {loading && <CircularProgress size={18} thickness={2} />}
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
      validate={validate}
      onSubmit={handleSubmit}
    />
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createTheme(kanarysTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;

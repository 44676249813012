import * as React from 'react';
import { EditGuesser, InputGuesser } from '@api-platform/admin';

import { Theme } from '../interfaces/theme';

const ThemeEdit = (props: Theme) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <InputGuesser source="oldResponse" />
    <InputGuesser source="newEditedResponse" />
    <InputGuesser source="theme" />
    <InputGuesser source="questionId" />
    <InputGuesser sourec="companyName" />
  </EditGuesser>
);

export default ThemeEdit;

import CompanyIcon from '@material-ui/icons/Business';

import CompanyEdit from './CompanyEdit';
import CompanyList from './CompanyList';
import CompanyShow from './CompanyShow';
import CompanyCreate from './CompanyCreate';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: CompanyCreate,
  list: CompanyList,
  edit: CompanyEdit,
  show: CompanyShow,
  icon: CompanyIcon,
};

import * as React from 'react';
import { ShowGuesser, FieldGuesser } from '@api-platform/admin';
import { ReferenceField, TextField, ReferenceArrayField, Datagrid, RichTextField } from 'react-admin';

import { SurveyFormStep } from '../interfaces/surveyformstep';

const SurveyFormStepShow = (props: SurveyFormStep) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="title" addLabel />
    <RichTextField source="description" addLabel />
    <FieldGuesser source="position" addLabel />
    <ReferenceField label="Question Category" reference="survey_forms" source="form">
      <TextField source="title" />
    </ReferenceField>
    <ReferenceArrayField addLabel={false} reference="survey_form_questions" source="formQuestions">
      <Datagrid>
        <TextField label="Questions" source="label" />
      </Datagrid>
    </ReferenceArrayField>
  </ShowGuesser>
);

export default SurveyFormStepShow;

import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
  ...englishMessages,
  pos: {
    search: 'Search',
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    dashboard: {
      monthly_revenue: 'Monthly Revenue',
      month_history: '30 Day Revenue History',
      new_orders: 'New Orders',
      pending_reviews: 'Pending Reviews',
      all_reviews: 'See all reviews',
      new_customers: 'New Customers',
      all_customers: 'See all customers',
      pending_orders: 'Pending Orders',
      order: {
        items: 'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
      },
      welcome: {
        title: 'Welcome to the react-admin e-commerce demo',
        subtitle: "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
        ra_button: 'react-admin site',
        demo_button: 'Source for this demo',
      },
    },
    menu: {
      article: 'Article',
      blog: 'Blog',
      newsroom: 'Newsroom',
      specificity: 'Specificities',
      company: 'Company',
      survey: 'Surveys',
      reviews: 'Reviews',
      theme: 'Theme',
      eoaResults: 'EOA Results',
      user: 'User',
      pages: 'Pages',
      emails: 'Emails',
      locales: 'Locales',
    },
  },
  resources: {
    article: {
      name: 'Kanarys Articles |||| Kanarys Articles',
      page: {
        delete: 'Delete Customer',
      },
    },
    blog: {
      name: 'Blog |||| Blog',
    },
    newsroom: {
      name: 'Newsroom |||| Newsroom',
    },
    specificity: {
      name: 'Specificities |||| Specificities',
      industries: {
        name: 'Industries',
      },
      employeeRanges: {
        name: 'Employee Ranges',
      },
      recommendations: {
        name: 'Recommendations',
      },
      benefits: {
        name: 'Benefits',
      },
      socialGroupIssues: {
        name: 'Social group issues',
      },
    },
    company: {
      name: 'Company |||| Company',
      fields: {
        products: 'Company',
      },
      companyRecommendations: {
        name: 'Company Recommendations',
      },
      banners: {
        name: 'Banners',
      },
    },
    survey: {
      name: 'Surveys |||| Surveys',
      companyGenericSurveys: {
        name: 'Company/Generic Surveys',
      },
      companySurveyTokens: {
        name: 'Surveys Tokens',
      },
      companyGenericSurveyQuestions: {
        name: 'Survey Questions',
      },
      companyGenericSurveyAnswers: {
        name: 'Company/Generic Survey Answers',
      },
      surveyCategory: {
        name: 'Survey Categories',
      },
      questionCategory: {
        name: 'Question Category',
      },
      surveyStep: {
        name: 'Survey Step Question',
      },
      surveyQuestion: {
        name: 'Questions/Answers',
      },
      pinpointCampaigns: {
        name: 'Pinpoint Campaigns',
      },
      campaignSchedules: {
        name: 'Campaign Schedules',
      },
      mediaImages: {
        name: 'Pinpoint Images',
      },
      pinpointCampaignUsers: {
        name: 'Company Member Data',
      },
    },
    theme: {
      name: 'Themes',
    },
    eoaResults: {
      name: 'EOA Results',
    },
    user: {
      name: 'Users |||| Users',
      userLikes: {
        name: 'Likes',
      },
      userShares: {
        name: 'Shares',
      },
      userFollows: {
        name: 'Follows',
      },
      userComments: {
        name: 'Comments',
      },
      userContributions: {
        name: 'Contributions',
      },
      companyAdmin: {
        name: 'Company Admin',
      },
      userActivityLogs: {
        name: 'User Activity Log',
      },
    },
    locales: {
      name: 'Language',
    },
    pages: {
      name: 'Pages |||| Pages',
    },
    emails: {
      name: 'Emails Template |||| Emails Template',
    },
    segments: {
      name: 'Segment |||| Segments',
      fields: {
        customers: 'Customers',
        name: 'Name',
      },
      data: {
        compulsive: 'Compulsive',
        collector: 'Collector',
        ordered_once: 'Ordered once',
        regular: 'Regular',
        returns: 'Returns',
        reviewer: 'Reviewer',
      },
    },
    resources: {
      survey_companies: {
        name: 'Survey Comapny',
        fields: {},
        filters: {},
        fieldGroups: {
          surveyCompanyQuestions: 'Survey Company Questions',
        },
      },
    },
  },
};

export default customEnglishMessages;

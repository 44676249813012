import * as React from 'react';
import { EditGuesser, InputGuesser } from '@api-platform/admin';
import { minLength, required, TextInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { Email } from '../interfaces/email';
const EmailEdit = (props: Email) => (
  <EditGuesser {...props}>
    <InputGuesser source="label" />
    <InputGuesser source="isPinpointTemplate" />
    <TextInput label="Subject" source="subject" validate={[required(), minLength(3)]} fullWidth multiline />
    <RichTextInput label="Body" source="body" fullWidth />
  </EditGuesser>
);

export default EmailEdit;

import * as React from 'react';
import { EditGuesser } from '@api-platform/admin';
import { AutocompleteArrayInput, BooleanInput, FormDataConsumer, ReferenceArrayInput, TextInput, required } from 'react-admin';

const BannerEdit = (props: JSX.IntrinsicAttributes) => (
  <EditGuesser {...props}>
    <TextInput label="Content" source="content" validate={required()} fullWidth />
    <BooleanInput defaultValue={false} label="Linked to Product" source="isInternal" fullWidth />

    <FormDataConsumer>{({ formData }) => !formData.isInternal && <TextInput label="Link" source="link" validate={formData.isInternal ? undefined : required()} fullWidth />}</FormDataConsumer>

    <BooleanInput defaultValue={false} label="Global Banner?" source="global" fullWidth />

    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.global === false && (
          <ReferenceArrayInput label="Companies" reference="companies" source="companies" validate={required()} {...rest}>
            <AutocompleteArrayInput optionText="name" />
          </ReferenceArrayInput>
        )
      }
    </FormDataConsumer>
    <ReferenceArrayInput label="Products" perPage={100} reference="products" source="products">
      <AutocompleteArrayInput optionText="name" optionValue="@id" />
    </ReferenceArrayInput>

    <ReferenceArrayInput label="Users" perPage={100} reference="users" source="users">
      <AutocompleteArrayInput optionText="email" optionValue="@id" />
    </ReferenceArrayInput>
  </EditGuesser>
);

export default BannerEdit;

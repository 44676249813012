import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { useTranslate, DashboardMenuItem, MenuItemLink, MenuProps, ReduxState } from 'react-admin';

import industries from '../industries';
import employeesRanges from '../employees_ranges';
import recommendations from '../recommendations';
import companyRecommendations from '../company_recommendations';
import banners from '../banners';
import categories from '../categories';
import users from '../users';
import surveyFormQuestions from '../survey_form_questions';
import surveyStepQuestion from '../survey_form_steps';
import companies from '../companies';
import pinpointCampaigns from '../pinpoint_campaigns';
import campaignSchedules from '../campaign_schedules';
import surveyCompanies from '../survey_companies';
import { AppState } from '../interfaces/types';
import pinpointCampaignUsers from '../pinpoint_campaign_users';
import mediaImages from '../media_images';
import admin from '../admin';
import userActivityLogs from '../user_activity_logs';
import pages from '../pages';
import emails from '../emails';
import locales from '../locales';
import themes from '../themes';
import eoa_scores from '../eoa_scores';

import SubMenu from './SubMenu';

type MenuName = 'menuArticle' | 'menuSurvey' | 'menuUser' | 'menuTheme' | 'menuEoaResults' | 'menuSpecificity' | 'menuCompany' | 'menuPage' | 'menuEmail' | 'menuLocale';

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuArticle: false,
    menuSpecificity: false,
    menuCompany: false,
    menuSurvey: true,
    menuUser: false,
    menuTheme: false,
    menuEoaResults: false,
    menuPage: false,
    menuEmail: false,
    menuLocale: false,
  });
  const translate = useTranslate();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    open: {
      width: 280,
    },
    closed: {
      width: 55,
    },
  }));

  const classes = useStyles();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}>
      <DashboardMenuItem />
      <SubMenu dense={dense} handleToggle={() => handleToggle('menuSpecificity')} icon={<industries.icon />} isOpen={state.menuSpecificity} name="pos.menu.specificity">
        <MenuItemLink
          dense={dense}
          leftIcon={<industries.icon />}
          placeholder=""
          primaryText={translate(`resources.specificity.industries.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/industries',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />

        <MenuItemLink
          dense={dense}
          leftIcon={<employeesRanges.icon />}
          placeholder=""
          primaryText={translate(`resources.specificity.employeeRanges.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/employees_ranges',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<recommendations.icon />}
          placeholder=""
          primaryText={translate(`resources.specificity.recommendations.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/recommendations',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle('menuCompany')} icon={<companies.icon />} isOpen={state.menuCompany} name="pos.menu.company">
        <MenuItemLink
          dense={dense}
          leftIcon={<companies.icon />}
          placeholder=""
          primaryText={translate(`resources.company.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/companies',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<companyRecommendations.icon />}
          placeholder=""
          primaryText={translate(`resources.company.companyRecommendations.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/company_recommendations',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<banners.icon />}
          placeholder=""
          primaryText={translate(`resources.company.banners.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/banners',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle('menuSurvey')} icon={<categories.icon />} isOpen={state.menuSurvey} name="pos.menu.survey">
        <MenuItemLink
          dense={dense}
          leftIcon={<categories.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.surveyCategory.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/survey_forms',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<surveyStepQuestion.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.surveyStep.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/survey_form_steps',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<categories.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.questionCategory.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/categories',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<surveyFormQuestions.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.surveyQuestion.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/survey_form_questions',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />

        <MenuItemLink
          dense={dense}
          leftIcon={<surveyCompanies.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.companyGenericSurveys.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/survey_companies',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<surveyCompanies.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.companySurveyTokens.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/tokens',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<surveyCompanies.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.companyGenericSurveyQuestions.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/survey_company_survey_form_questions',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<pinpointCampaigns.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.pinpointCampaigns.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/pinpoint_campaigns',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />

        <MenuItemLink
          dense={dense}
          leftIcon={<campaignSchedules.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.campaignSchedules.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/campaign_schedules',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />

        <MenuItemLink
          dense={dense}
          leftIcon={<mediaImages.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.mediaImages.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/media_images',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />

        <MenuItemLink
          dense={dense}
          leftIcon={<pinpointCampaignUsers.icon />}
          placeholder=""
          primaryText={translate(`resources.survey.pinpointCampaignUsers.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/pinpoint_campaign_users',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle('menuTheme')} icon={<themes.icon />} isOpen={state.menuTheme} name="pos.menu.theme">
        <MenuItemLink
          dense={dense}
          leftIcon={<themes.icon />}
          placeholder=""
          primaryText={translate(`resources.theme.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/themes',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle('menuEoaResults')} icon={<eoa_scores.icon />} isOpen={state.menuEoaResults} name="pos.menu.eoaResults">
        <MenuItemLink
          dense={dense}
          leftIcon={<themes.icon />}
          placeholder=""
          primaryText={translate(`resources.eoaResults.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/eoa_scores',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle('menuUser')} icon={<users.icon />} isOpen={state.menuUser} name="pos.menu.user">
        <MenuItemLink
          dense={dense}
          leftIcon={<users.icon />}
          placeholder=""
          primaryText={translate(`resources.user.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/users',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<userActivityLogs.icon />}
          placeholder=""
          primaryText={translate(`resources.user.userActivityLogs.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/user_activity_logs',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
        <MenuItemLink
          dense={dense}
          leftIcon={<admin.icon />}
          placeholder=""
          primaryText={translate(`resources.user.companyAdmin.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/admins',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle('menuPage')} icon={<pages.icon />} isOpen={state.menuPage} name="pos.menu.pages">
        <MenuItemLink
          dense={dense}
          leftIcon={<pages.icon />}
          placeholder=""
          primaryText={translate(`resources.pages.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/pages',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle('menuEmail')} icon={<emails.icon />} isOpen={state.menuEmail} name="pos.menu.emails">
        <MenuItemLink
          dense={dense}
          leftIcon={<emails.icon />}
          placeholder=""
          primaryText={translate(`resources.emails.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/emails',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      </SubMenu>
      <SubMenu dense={dense} handleToggle={() => handleToggle('menuLocale')} icon={<locales.icon />} isOpen={state.menuLocale} name="pos.menu.locales">
        <MenuItemLink
          dense={dense}
          leftIcon={<locales.icon />}
          placeholder=""
          primaryText={translate(`resources.locales.name`, {
            smart_count: 2,
          })}
          to={{
            pathname: '/locales',
            state: { _scrollToTop: true },
          }}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        />
      </SubMenu>
    </div>
  );
};

export default Menu;

import * as React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { RichTextField, Filter, ReferenceInput, AutocompleteInput } from 'react-admin';
import { EoaScore } from '../interfaces/eoa_results';

const EoaResultsFilters = (props: any) => (
  <Filter {...props}>
    <ReferenceInput allowEmpty={false} filter={{ productType: 'eoa' }} filterToQuery={(searchText) => ({ title: searchText })} label="Survey Company" reference="survey_companies" source="surveyCompany">
      <AutocompleteInput optionText="title" optionValue="@id" />
    </ReferenceInput>
  </Filter>
);

const EoaResultsList = (props: EoaScore) => (
  <ListGuesser filters={<EoaResultsFilters />} {...props} exporter={false} sort={{ field: 'createdAt', order: 'DESC' }} hasCreate={false}>
    <RichTextField source="surveyCompany.title" label="SurveyCompany" />
    <RichTextField source="company.name" label="Company" />
    <RichTextField source="visionStrategy" />
    <RichTextField source="deiInfrastructure" label="DEI Infrastructure" />
    <RichTextField source="pipelineSourcing" />
    <RichTextField source="screeningBiasMitigation" />
    <RichTextField source="interviewPractices" />
    <RichTextField source="developmentAdvocacy" />
    <RichTextField source="performanceEvaluation" />
    <RichTextField source="equitableBenefits" />
    <RichTextField source="payEquity" />
    <RichTextField source="learningDevelopment" />
    <RichTextField source="assessmentResearch" />
    <RichTextField source="deiMarketingCommunications" label="DEI Marketing Communications" />
    <RichTextField source="sustainability" />
    <RichTextField source="socialResponsibility" />
    <RichTextField source="supplierDiversity" />
    <RichTextField source="leadershipPulse" />
    <RichTextField source="transformationalReadiness" />
    <RichTextField source="inclusiveHiring" />
    <RichTextField source="talentEnrichment" />
    <RichTextField source="cultureBelonging" />
    <RichTextField source="communitySocialImpact" />
    <RichTextField source="overall" />
    <RichTextField source="overallRecommendations" />
    <RichTextField source="transformationalReadinessStrengths" />
    <RichTextField source="inclusiveHiringStrengths" />
    <RichTextField source="talentEnrichmentStrengths" />
    <RichTextField source="cultureBelongingStrengths" />
    <RichTextField source="communitySocialImpactStrengths" />
    <RichTextField source="transformationalReadinessRecommendations" />
    <RichTextField source="inclusiveHiringRecommendations" />
    <RichTextField source="talentEnrichmentRecommendations" />
    <RichTextField source="cultureBelongingRecommendations" />
    <RichTextField source="communitySocialImpactRecommendations" />
  </ListGuesser>
);

export default EoaResultsList;

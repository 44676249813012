import * as React from 'react';
import { ShowGuesser, FieldGuesser } from '@api-platform/admin';
import { TextField, RichTextField } from 'react-admin';

import { Email } from '../interfaces/email';

const EmailShow = (props: Email) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="label" addLabel />
    <FieldGuesser source="isPinpointTemplate" addLabel />
    <TextField source="subject" />
    <RichTextField source="body" />
  </ShowGuesser>
);
export default EmailShow;

import * as React from 'react';
import { FunctionField } from 'react-admin';
import { useRecordContext } from 'react-admin';

const StatusField = ({ source }: any) => {
  const record = useRecordContext();
  const status = record[source];

  return record ? <FunctionField emptyText="N/A" label="Status" render={() => `${status === 1 ? 'Completed' : status === 2 ? 'Cancelled' : 'Pending'}`} sortable={false} source={source} /> : null;
};

StatusField.defaultProps = {
  addLabel: true,
  addField: true,
  source: 'status',
};

export { StatusField };

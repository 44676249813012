import * as React from 'react';
import { InputGuesser, EditGuesser } from '@api-platform/admin';
import { ImageField, ReferenceArrayInput, AutocompleteArrayInput, Toolbar, SaveButton, DeleteWithConfirmButton, useRecordContext } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';

const MediaImageTitle = (props: any) => {
  return <span>Media Image {props.record ? `"${props.record.imageAlt}"` : ''}</span>;
};
export const styles: Styles<Theme, any> = {
  defaultToolbar: { flex: 1, display: 'flex', justifyContent: 'space-between' },
};
const useStyles = makeStyles(styles);

const MediaImageEditToolbar = (props: any) => {
  const classes = useStyles(props);
  const record = useRecordContext();

  return (
    <Toolbar {...props} className={classes.defaultToolbar}>
      <SaveButton />
      <DeleteWithConfirmButton confirmContent="You will not be able to recover this record. Are you sure?" confirmTitle={`Delete media image #${record.imageAlt}`} />
    </Toolbar>
  );
};

const MediaImageEdit = (props: any) => (
  <EditGuesser title={<MediaImageTitle />} toolbar={<MediaImageEditToolbar />} {...props}>
    <InputGuesser label="Image Alt Text" source="imageAlt" />
    <ImageField source="imageUrl" title="title" />
    <RichTextInput label="Login Top Text" source="loginTextTop" />
    <RichTextInput label="Login Bottom Text" source="loginTextBottom" />
    <ReferenceArrayInput filterToQuery={(searchText: string) => ({ campaignName: searchText })} label="Pinpoint Campaigns" reference="pinpoint_campaigns" source="pinpointCampaigns">
      <AutocompleteArrayInput optionText="campaignName" optionValue="@id" />
    </ReferenceArrayInput>
  </EditGuesser>
);

export default MediaImageEdit;

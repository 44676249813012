import * as React from 'react';
import { CreateGuesser, InputGuesser } from '@api-platform/admin';
import { ArrayInput, minLength, required, SimpleFormIterator, TextInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const PageCreate = (props: any) => (
  <CreateGuesser {...props}>
    <InputGuesser source="pageTitle" />
    <InputGuesser source="slug" />
    <ArrayInput source="pageSections">
      <SimpleFormIterator>
        <TextInput label="Title" source="title" validate={[required(), minLength(3)]} fullWidth multiline />
        <RichTextInput label="Content" source="content" fullWidth />
      </SimpleFormIterator>
    </ArrayInput>
  </CreateGuesser>
);

export default PageCreate;

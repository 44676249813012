import LocaleIcon from '@material-ui/icons/Language';

import LocaleCreate from './LocaleCreate';
import LocaleList from './LocaleList';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  icon: LocaleIcon,
  list: LocaleList,
  create: LocaleCreate,
};

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { ReferenceArrayField, TextField, SingleFieldList, BooleanField } from 'react-admin';

import { Banner } from '../interfaces/banner';

const BannerList = (props: Banner) => (
  <ListGuesser {...props} exporter={false}>
    <FieldGuesser label="Message" source="content" />
    <FieldGuesser source="link" />
    <BooleanField label="Global Banner" source="global" />
    <ReferenceArrayField label="Company" reference="companies" source="companies">
      <SingleFieldList linkType={false}>
        <TextField addLabel={false} source="name" />
      </SingleFieldList>
    </ReferenceArrayField>
  </ListGuesser>
);

export default BannerList;

import EmailIcon from '@material-ui/icons/Email';

import EmailsList from './EmailsList';
import EmailEdit from './EmailEdit';
import EmailShow from './EmailShow';
import EmailCreate from './EmailCreate';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: EmailsList,
  edit: EmailEdit,
  create: EmailCreate,
  show: EmailShow,
  icon: EmailIcon,
};

import * as React from 'react';
import { EditGuesser, InputGuesser } from '@api-platform/admin';
import { ReferenceInput, SelectInput, ReferenceArrayInput, AutocompleteArrayInput, AutocompleteInput, useRecordContext, required, DateTimeInput, BooleanInput, minLength, maxLength } from 'react-admin';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles/withStyles';
import { Box } from '@material-ui/core';

import { SurveyCompany } from '../interfaces/surveycompany';

import { SurveyCompanyArrayToken } from './SurveyCompanyToken';
import { SurveyCompanyArrayInput } from './SurveyCompanyInput';

export const styles: Styles<Theme, any> = {
  title: { display: 'inline-block', marginRight: '1rem', width: 720 },
  company: { display: 'inline-block', marginRight: '1rem', width: 360 },
  deploymentType: { display: 'inline-block', marginRight: '1rem', width: 360 },
  surveyForms: { display: 'inline-block', marginRight: '1rem', minWidth: 360, maxWidth: 720 },
  inlineBlock: { display: 'inline-block', marginRight: '1rem', width: 360 },
  surveyCompanyQuestions: { display: 'flex' },
  label: { display: 'inline-block', marginRight: '1rem', width: '25em' },
  rank: { display: 'inline-block', marginRight: '1rem', minWidth: 60, maxWidth: 80 },
  maxAnswerSelection: { display: 'inline-block', marginRight: '1rem', marginLeft: '1rem', width: 100 },
  isRequired: { display: 'inline-block', marginRight: '1rem', width: 160 },
  isVisible: { display: 'inline-block', marginRight: '1rem', width: 160 },
  typeRestriction: { display: 'inline-block', marginRight: '1rem', width: 120 },
  info: { display: 'inline-block', marginRight: '1rem', marginLeft: '1rem', width: 300 },
};
const useStyles = makeStyles(styles);

const SurveyCompanyTitle = (props: SurveyCompany) => {
  const surveyCompany = useRecordContext<SurveyCompany>(props);

  return <span>Edit Company/Generic Survey {surveyCompany ? `"${surveyCompany.title}"` : ''}</span>;
};

const Separator = () => <Box pt="1em" />;

const validateRequired = [required()];

const SurveyCompanyEdit = (props: SurveyCompany) => {
  const classes = useStyles(props);

  return (
    <EditGuesser title={<SurveyCompanyTitle />} {...props}>
      <InputGuesser formClassName={classes.title} source="title" validate={[required(), minLength(5), maxLength(120)]} fullWidth />
      <Separator />
      <ReferenceInput formClassName={classes.deploymentType} label="Deployment Type" reference="deployment_types" source="deploymentType" validate={validateRequired} fullWidth>
        <SelectInput />
      </ReferenceInput>

      <ReferenceInput filterToQuery={(searchText) => ({ name: searchText })} formClassName={classes.company} label="Company" reference="companies" source="company" fullWidth>
        <AutocompleteInput optionText="name" optionValue="@id" />
      </ReferenceInput>
      <Separator />
      <ReferenceArrayInput filterToQuery={(searchText: any) => ({ title: searchText })} formClassName={classes.surveyForms} label="Survey Categories" reference="survey_forms" source="surveyForms" validate={validateRequired} fullWidth>
        <AutocompleteArrayInput optionText="title" optionValue="@id" />
      </ReferenceArrayInput>

      <Separator />
      <DateTimeInput formClassName={classes.inlineBlock} source="startDate" validate={validateRequired} fullWidth />
      <DateTimeInput formClassName={classes.inlineBlock} source="endDate" validate={validateRequired} fullWidth />

      <Separator />
      <BooleanInput label="Is Active?" source="isActive" />

      <BooleanInput label="Bypass Authentication" source="byPassAuthentication" />
      <BooleanInput label="Randomize Survey Questions and Categories" source="randomizeSurveyQuestionsCategories" />

      <SurveyCompanyArrayToken source="surveyCompanyTokens" />
      <SurveyCompanyArrayInput source="categories" />
    </EditGuesser>
  );
};

export default SurveyCompanyEdit;

import * as React from 'react';
import { FieldGuesser, ShowGuesser } from '@api-platform/admin';
import { RichTextField } from 'react-admin';

import { Recommendation } from '../interfaces/recommendation';

const RecommendationShow = (props: Recommendation) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel />
    <RichTextField source="description" addLabel />
    <FieldGuesser source="level" addLabel />
  </ShowGuesser>
);

export default RecommendationShow;

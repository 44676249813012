import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra, useIntrospection } from '@api-platform/admin';
import { parseHydraDocumentation } from '@api-platform/api-doc-parser';

const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT || '';

const getHeaders: any = () => (localStorage.getItem('token') ? { Authorization: `Bearer ${localStorage.getItem('token')}` } : {});
const fetchHydra = (url = null, options = {}) => baseFetchHydra(url, { ...options, headers: getHeaders });

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (localStorage.getItem('token')) {
    introspect();

    return <></>;
  }

  return <Redirect to="/login" />;
};

const apiDocumentationParser = async () => {
  try {
    return await parseHydraDocumentation(ENTRYPOINT, { headers: getHeaders });
  } catch (result: any) {
    const { api, response, status } = result;

    if (status !== 401 || !response) {
      throw result;
    }
    localStorage.removeItem('token');

    return { api, response, status, customRoutes: [<Route key="/" component={RedirectToLogin} path="/" />] };
  }
};

const dataProvider = baseHydraDataProvider({
  entrypoint: ENTRYPOINT,
  httpClient: fetchHydra,
  apiDocumentationParser,
  mercure: true,
  useEmbedded: true,
});

export default dataProvider;

import * as React from 'react';
import { FunctionField } from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import { useRecordContext } from 'react-admin';

import { questionTypes, fileTypes, dateTimes } from './dropdowndata';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: -8,
    marginBottom: -8,
  },
  chip: { margin: 4 },
});

const QuestionTypeField = ({ source }: any) => {
  const record = useRecordContext();
  const questionType: any = questionTypes.find((item: any) => record[source] === item.id);

  return record ? <FunctionField label="Question Type" render={() => `${questionType.name}`} /> : null;
};

QuestionTypeField.defaultProps = {
  addLabel: true,
  addField: true,
  source: 'type',
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const FileTypeField = ({ source }: any) => {
  const record = useRecordContext();
  const classes = useStyles();

  return record ? (
    <span className={classes.main}>
      {record?.fileTypeAnswer?.allowedFiles &&
        record?.fileTypeAnswer?.allowedFiles.map((allowedFileId: any) => {
          const fileType = fileTypes.find((s) => s.id === allowedFileId);

          return fileType ? <Chip key={fileType.id} className={classes.chip} label={`${fileType.name}`} size="small" /> : null;
        })}
    </span>
  ) : null;
};

FileTypeField.defaultProps = {
  addLabel: true,
  source: 'allowedFiles',
};

/* eslint-disable @typescript-eslint/no-unused-vars */
const DateTimeTypeField = ({ source }: any) => {
  const record = useRecordContext();
  const classes = useStyles();

  return record ? (
    <span className={classes.main}>
      {record?.dateTimeTypeAnswer?.dateTime &&
        record?.dateTimeTypeAnswer?.dateTime.map((dateT: any) => {
          const dateTime = dateTimes.find((s) => s.id === dateT);

          return dateTime ? <Chip key={dateTime.id} className={classes.chip} label={`${dateTime.name}`} size="small" /> : null;
        })}
    </span>
  ) : null;
};

DateTimeTypeField.defaultProps = {
  addLabel: true,
  source: 'dateTime',
};
export { QuestionTypeField, FileTypeField, DateTimeTypeField };

import * as React from 'react';
import { Create, SimpleForm, TextInput, BooleanInput, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, required, useNotify, useRedirect, CreateProps } from 'react-admin';

const BannerCreate = (props: CreateProps) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify('Banner created successfully');
    redirect('/banners');
  };

  return (
    <Create {...props} onSuccess={onSuccess}>
      <SimpleForm>
        <TextInput label="Content" source="content" validate={required()} fullWidth />
        <BooleanInput defaultValue={false} label="Linked to Product" source="isInternal" fullWidth />

        <FormDataConsumer>
          {
            ({ formData }) => !formData.isInternal && <TextInput label="Link" source="link" fullWidth /> // Invert this condition to show the field when isInternal is false
          }
        </FormDataConsumer>

        <BooleanInput defaultValue={false} label="Global Banner?" source="global" fullWidth />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.global === false && (
              <ReferenceArrayInput label="Companies" reference="companies" source="companies" validate={required()} {...rest}>
                <AutocompleteArrayInput optionText="name" />
              </ReferenceArrayInput>
            )
          }
        </FormDataConsumer>
        <ReferenceArrayInput label="Products" perPage={100} reference="products" source="products">
          <AutocompleteArrayInput optionText="name" optionValue="@id" />
        </ReferenceArrayInput>

        <ReferenceArrayInput label="Users" perPage={100} reference="users" source="users">
          <AutocompleteArrayInput optionText="email" optionValue="@id" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export default BannerCreate;

import * as React from 'react';
import { InputGuesser, EditGuesser } from '@api-platform/admin';
import { SelectInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';

import { SurveyForm } from '../interfaces/surveyform';

import SurveyFormTitle from './SurveyFormTitle';
const SurveyFormEdit = (props: SurveyForm) => (
  <EditGuesser {...props} title={<SurveyFormTitle />} warnWhenUnsavedChanges>
    <InputGuesser source="title" />
    <ReferenceArrayInput label="Survey" reference="survey_companies" source="surveyCompany">
      <AutocompleteArrayInput optionText="title" optionValue="@id" />
    </ReferenceArrayInput>
    <SelectInput
      choices={[
        { id: 'general', name: 'General' },
        { id: 'genders', name: 'Genders' },
        { id: 'races', name: 'Races' },
        { id: 'sexual_orientations', name: 'Sexual Orientations' },
        { id: 'religions', name: 'Religions' },
      ]}
      defaultValue="type"
      optionText="name"
      optionValue="id"
      source="type"
    />
    <InputGuesser source="isActive" />
  </EditGuesser>
);

export default SurveyFormEdit;

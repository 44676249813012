import * as React from 'react';
import { FunctionField } from 'react-admin';
import { useRecordContext } from 'react-admin';

import { campaignsFormTypes, campaignTypes, channelTypes, frequencyTypes, retargetTypes, scheduleStartTypes } from './pinpoint-campaign-drop-down-data';

const ScheduleFrequencyField = ({ source }: any) => {
  const record = useRecordContext();
  const scheduleFrequency: any = frequencyTypes.find((item: any) => record[source] === item.id);

  return record ? <FunctionField label="Schedule Frequency" render={() => `${scheduleFrequency.name}`} /> : null;
};

ScheduleFrequencyField.defaultProps = {
  addLabel: true,
  addField: true,
  source: 'scheduleFrequency',
};

const ScheduleStarttypeField = ({ source }: any) => {
  const record = useRecordContext();
  const scheduleStarttype: any = scheduleStartTypes.find((item: any) => record[source] === item.id);

  return record ? <FunctionField label="Start Type" render={() => `${scheduleStarttype.name}`} /> : null;
};

ScheduleStarttypeField.defaultProps = {
  addLabel: true,
  addField: true,
  source: 'scheduleStarttype',
};

const ChannelTypeField = ({ source }: any) => {
  const record = useRecordContext();
  const channelType: any = channelTypes.find((item: any) => record[source] === item.id);

  return record ? <FunctionField label="Channel Type" render={() => `${channelType.name}`} /> : null;
};

ChannelTypeField.defaultProps = {
  addLabel: true,
  addField: true,
  source: 'channelType',
};

const FormTypeField = ({ source }: any) => {
  const record = useRecordContext();
  const formType: any = campaignsFormTypes.find((item: any) => record[source] === item.id);

  return record ? <FunctionField label="Display Type" render={() => `${formType.name}`} /> : null;
};

FormTypeField.defaultProps = {
  addLabel: true,
  addField: true,
  source: 'formType',
};

const CampaignTypeField = ({ source }: any) => {
  const record = useRecordContext();
  const campaignType: any = campaignTypes.find((item: any) => record[source] === item.id);

  return record ? <FunctionField label="Campaign Type" render={() => `${campaignType.name}`} /> : null;
};

CampaignTypeField.defaultProps = {
  addLabel: true,
  addField: true,
  source: 'campaignType',
};

const RetargetTypeField = ({ source }: any) => {
  const record = useRecordContext();
  const retargetType: any = retargetTypes.find((item: any) => record[source] === item.id);

  return record ? <FunctionField label="Re-target Type" render={() => `${retargetType.name}`} /> : null;
};

RetargetTypeField.defaultProps = {
  addLabel: true,
  addField: true,
  source: 'retargetType',
};

export { ScheduleFrequencyField, ScheduleStarttypeField, FormTypeField, ChannelTypeField, CampaignTypeField, RetargetTypeField };

import * as React from 'react';
import { InputGuesser, CreateGuesser } from '@api-platform/admin';
import { ReferenceInput, AutocompleteInput, required, NumberInput, minValue, maxValue, FormDataConsumer, useInput } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { CompanyRecommendation } from '../interfaces/company_recommendation';

const HiddenInputComponent = ({ source, defaultValue }: { source: string; defaultValue: any }) => {
  const {
    input: { name, value },
  } = useInput({ source, defaultValue });

  return <input name={name} type="hidden" value={value} />;
};

const validateRequired = [required()];
const validateLevel = [minValue(1), maxValue(5)];

const CompanyRecommendationCreate = (props: CompanyRecommendation) => (
  <CreateGuesser {...props}>
    <InputGuesser source="name" />
    <RichTextInput label="Original Description" source="content" fullWidth />
    <RichTextInput label="Customized Description" source="description" fullWidth />
    <NumberInput max={5} min={1} source="level" validate={validateLevel} />

    {/* Disabled InputGuesser for isSelected */}
    <HiddenInputComponent defaultValue={false} source="isSelected" />
    <HiddenInputComponent defaultValue={false} source="phase" />
    <HiddenInputComponent defaultValue={false} source="color" />

    <ReferenceInput label="Original Category" perPage={100} reference="categories" source="category" validate={validateRequired}>
      <AutocompleteInput optionText="title" optionValue="@id" />
    </ReferenceInput>
    <InputGuesser label="Customized Category" source="categoryTitle" validate={validateRequired} />
    <ReferenceInput label="Company" perPage={100} reference="companies" source="company" validate={validateRequired}>
      <AutocompleteInput optionText="name" optionValue="@id" />
    </ReferenceInput>
    <ReferenceInput label="Original Recommendation" perPage={100} reference="recommendations" source="recommendation" validate={validateRequired}>
      <AutocompleteInput optionText="name" optionValue="@id" />
    </ReferenceInput>

    {/* Using FormDataConsumer to set isSelected to false */}
    <FormDataConsumer>
      {({ formData }) => {
        formData.isSelected = false;
        formData.phase = 'Phase 1';
        formData.color = 'yellow';

        return null;
      }}
    </FormDataConsumer>
  </CreateGuesser>
);

export default CompanyRecommendationCreate;

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { RichTextField } from 'react-admin';

import { Recommendation } from '../interfaces/recommendation';

const RecommendationList = (props: Recommendation) => (
  <ListGuesser {...props} exporter={false}>
    <FieldGuesser source="name" />
    <RichTextField source="description" />
    <FieldGuesser source="level" />
  </ListGuesser>
);

export default RecommendationList;

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { EmailField, FunctionField, Filter, SearchInput, ReferenceInput, AutocompleteInput, TextInput, DateField } from 'react-admin';

import { User } from '../interfaces/user';
import ColoredBooleanFields from '../common/ColoredBooleanFields';

import { EnableInput, RoleInput, UserTypeInput } from './UserInputs';
import { RoleField } from './UserFields';

const UserFilters = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Email" source="email" alwaysOn resettable />
    <TextInput label="First Name" source="firstName" />
    <TextInput label="Last Name" source="lastName" />
    <ReferenceInput filterToQuery={(searchText) => ({ name: searchText })} label="Company" reference="companies" source="company" resettable>
      <AutocompleteInput />
    </ReferenceInput>
    <EnableInput allowEmpty={false} />
    <UserTypeInput allowEmpty={false} />
    <RoleInput allowEmpty={false} />
  </Filter>
);

const UserList = (props: User) => (
  <ListGuesser {...props} exporter={false} filters={<UserFilters />} sort={{ field: 'createdAt', order: 'DESC' }}>
    <FunctionField label="Name" render={(user: any) => `${user.firstName} ${user.lastName}`} />
    <EmailField source="email" />
    <FieldGuesser label="Unique Id" source="uniqueId" />
    <ColoredBooleanFields label="Active?" source="enable" />
    <RoleField />
    <ColoredBooleanFields label="Display Welcome?" source="enableWelcome" />
    <DateField label="Created At" source="createdAt" />
  </ListGuesser>
);

export default UserList;

import * as React from 'react';
import { EditGuesser } from '@api-platform/admin';
import { ReferenceInput, AutocompleteInput, required } from 'react-admin';
import { InputGuesser } from '@api-platform/admin';

const AdminEdit = (props: any) => (
  <EditGuesser {...props}>
    <ReferenceInput filter={{ enable: true }} filterToQuery={(searchText: any) => ({ email: searchText })} label="User" perPage={10} reference="users" source="user" validate={[required()]} disabled fullWidth>
      <AutocompleteInput optionText="fullNameWithEmail" optionValue="@id" />
    </ReferenceInput>
    <ReferenceInput filter={{ validated: true }} filterToQuery={(searchText: any) => ({ name: searchText })} label="Company" reference="companies" source="company" validate={[required()]} fullWidth>
      <AutocompleteInput optionText="name" optionValue="@id" />
    </ReferenceInput>
    <InputGuesser label="Notify user?" source="isNotify" fullWidth />
    <InputGuesser label="Notify user for Okta?" source="isNotifyForOkta" fullWidth />
  </EditGuesser>
);

export default AdminEdit;

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';

const LocaleList = (props: any) => (
  <ListGuesser {...props}>
    <FieldGuesser source="code" />
    <FieldGuesser source="name" />
    <FieldGuesser source="isDefault" />
  </ListGuesser>
);

export default LocaleList;

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { TextField, ReferenceField, ReferenceInput, AutocompleteInput, Filter, SearchInput } from 'react-admin';

import { SurveyCompany } from '../interfaces/surveycompany';
import ColoredBooleanFields from '../common/ColoredBooleanFields';

import ReArrangeQuestionButton from './ReArrangeQuestionButton';
import IsRequiredQuestionButton from './IsRequiredQuestionButton';

const SurveyCompanyFilters = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Title" source="title" alwaysOn resettable />
    <ReferenceInput filterToQuery={(searchText) => ({ name: searchText })} label="Company" reference="companies" source="company">
      <AutocompleteInput />
    </ReferenceInput>
    <ReferenceInput filterToQuery={(searchText) => ({ name: searchText })} label="Deployment Type" reference="deployment_types" source="deploymentType">
      <AutocompleteInput />
    </ReferenceInput>
  </Filter>
);

const SurveyCompanyList = (props: SurveyCompany) => (
  <ListGuesser filters={<SurveyCompanyFilters />} {...props} exporter={false} sort={{ field: 'createdAt', order: 'DESC' }}>
    <FieldGuesser source="title" />
    <ReferenceField label="Company" link={false} reference="companies" sortBy="company.name" source="company">
      <TextField source="name" />
    </ReferenceField>
    <ReferenceField label="Deployment Type" link={false} reference="deployment_types" sortBy="deploymentType.name" source="deploymentType">
      <TextField source="name" />
    </ReferenceField>
    <TextField label="Categories" source="totalForms" />
    <TextField label="Questions" source="totalQuestion" />
    <TextField label="Tokens" source="totalToken" />
    <FieldGuesser source="startDate" />
    <FieldGuesser source="endDate" />
    <ColoredBooleanFields source="isActive" />
    <ReArrangeQuestionButton />
    <IsRequiredQuestionButton />
  </ListGuesser>
);

export default SurveyCompanyList;

import * as React from 'react';
import { ShowGuesser } from '@api-platform/admin';
import { RichTextField } from 'react-admin';

import { Theme } from '../interfaces/theme';

const ThemeShow = (props: Theme) => (
  <ShowGuesser {...props} exporter={false}>
    <RichTextField source="name" />
    <RichTextField source="oldResponse" />
    <RichTextField source="newEditedResponse" />
    <RichTextField source="theme" />
    <RichTextField source="questionId" />
    <RichTextField source="companyName" />
  </ShowGuesser>
);

export default ThemeShow;

import * as React from 'react';
import { useState } from 'react';
import { Button, Loading, useRefresh } from 'react-admin';
import PropTypes from 'prop-types';
const ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT || '';

const UpdateStatusButton = (props: any) => {
  const [isLoading, setLoading] = useState(false);
  const refresh = useRefresh();
  const { record } = props;
  const timeDifferenceThreshold = 15 * 60 * 1000;
  const currentUtcTime = new Date().getTime();

  const scheduleStarttimeUtc = new Date(record.scheduleStarttime).getTime();

  let isUpdateStatusEnabled;

  if (scheduleStarttimeUtc && currentUtcTime - scheduleStarttimeUtc < timeDifferenceThreshold) {
    isUpdateStatusEnabled = true;
  } else {
    isUpdateStatusEnabled = false;
  }

  const updateDeliveryStatus = async () => {
    setLoading(true);
    const request = new Request(`${ENTRYPOINT}/pinpoint_campaigns/update/${record.originId}`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }),
    });
    await fetch(request).then((response) => response.json());
    setLoading(false);
    refresh();

    if (!isLoading) {
      return <Loading />;
    }
  };

  return <Button disabled={isLoading || isUpdateStatusEnabled} label="Update Status" size="small" onClick={updateDeliveryStatus} />;
};

UpdateStatusButton.propTypes = {
  push: PropTypes.func,
  record: PropTypes.object,
  showNotification: PropTypes.func,
};

export default UpdateStatusButton;

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { RichTextField, Filter, ReferenceInput, AutocompleteInput, ReferenceField, TextField } from 'react-admin';

import { Theme } from '../interfaces/theme';

const ThemeFilters = (props: any) => (
  <Filter {...props}>
    <ReferenceInput allowEmpty={false} filter={{ productType: 'eoa-pulse' }} filterToQuery={(searchText) => ({ title: searchText })} label="Survey Company" reference="survey_companies" source="surveyCompany">
      <AutocompleteInput optionText="title" optionValue="@id" />
    </ReferenceInput>
  </Filter>
);

const ThemeList = (props: Theme) => (
  <ListGuesser filters={<ThemeFilters />} {...props} exporter={false} sort={{ field: 'createdAt', order: 'DESC' }}>
    <FieldGuesser source="name" />
    <FieldGuesser source="companyName" label="Company" />
    <ReferenceField label="Survey Company" reference="survey_companies" sortBy="surveyCompany.title" source="surveyCompany">
      <TextField source="title" />
    </ReferenceField>
    <RichTextField source="oldResponse" />
    <FieldGuesser source="questionId" />
  </ListGuesser>
);

export default ThemeList;

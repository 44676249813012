import * as React from 'react';
import { CreateGuesser, InputGuesser } from '@api-platform/admin';
import { ReferenceInput, AutocompleteInput, required } from 'react-admin';

const AdminCreate = (props: any) => (
  <CreateGuesser {...props}>
    <ReferenceInput filterToQuery={(searchText: any) => ({ email: searchText })} label="User" perPage={10} reference="users" source="user" validate={[required()]} fullWidth>
      <AutocompleteInput optionText="fullNameWithEmail" optionValue="@id" />
    </ReferenceInput>
    <ReferenceInput filter={{ validated: true }} filterToQuery={(searchText: any) => ({ name: searchText })} label="Company" reference="companies" source="company" validate={[required()]} fullWidth>
      <AutocompleteInput />
    </ReferenceInput>
    <InputGuesser label="Notify user?" source="isNotify" fullWidth />
    <InputGuesser label="Notify user for Okta?" source="isNotifyForOkta" fullWidth />
  </CreateGuesser>
);

export default AdminCreate;

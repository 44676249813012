import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { Filter, SearchInput } from 'react-admin';

const IndustryFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Title" source="name" alwaysOn resettable />
  </Filter>
);
const IndustryList = (props: any) => (
  <ListGuesser filters={<IndustryFilter />} {...props} exporter={false}>
    <FieldGuesser source="name" />
    <FieldGuesser label="Average Score" source="average" />
  </ListGuesser>
);

export default IndustryList;

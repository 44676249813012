import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { Filter, SearchInput } from 'react-admin';

import { Email } from '../interfaces/email';

const EmailFilter = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Title" source="label" alwaysOn resettable />
  </Filter>
);

const EmailsList = (props: Email) => (
  <ListGuesser filters={<EmailFilter />} {...props} exporter={false} {...props}>
    <FieldGuesser source="label" />
    <FieldGuesser source="isPinpointTemplate" />
    <FieldGuesser source="subject" />
  </ListGuesser>
);

export default EmailsList;

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
import { TextField, ReferenceInput, AutocompleteInput, Filter, FormDataConsumer } from 'react-admin';

import { SurveyCompanySurveyFormQuestion } from '../interfaces/surveycompanysurveyformquestion';

const SurveyCompanySurveyFormQuestionFilters = (props: any) => (
  <Filter {...props}>
    <ReferenceInput filterToQuery={(searchText) => ({ title: searchText })} label="Survey Company" reference="survey_companies" source="surveyCompany" alwaysOn>
      <AutocompleteInput optionText="title" optionValue="@id" />
    </ReferenceInput>

    <FormDataConsumer source="surveyFormQuestion" alwaysOn>
      {({ formData, ...rest }) =>
        formData && formData?.surveyCompany ? (
          <ReferenceInput filter={{ surveyCompany: formData?.surveyCompany }} filterToQuery={(searchText) => ({ label: searchText })} label="Survey Question" reference="survey_form_questions" source="surveyFormQuestion" allowEmpty {...rest}>
            <AutocompleteInput optionText="label" optionValue="@id" clearAlwaysVisible resettable />
          </ReferenceInput>
        ) : null
      }
    </FormDataConsumer>
  </Filter>
);

const SurveyCompanySurveyFormQuestionList = (props: SurveyCompanySurveyFormQuestion) => (
  <ListGuesser actions={null} bulkActionButtons={false} exporter={false} filters={<SurveyCompanySurveyFormQuestionFilters />} hasCreate={false} noDelete={false} perPage={100} {...props}>
    <TextField source="questionTitle" />
    <FieldGuesser source="level" />
    <FieldGuesser source="isRequired" />
    <FieldGuesser source="isVisible" />
  </ListGuesser>
);

export default SurveyCompanySurveyFormQuestionList;

import SurveyCompanyIcon from '@material-ui/icons/ReceiptTwoTone';

import SurveyCompanyCreate from './SurveyCompanyCreate';
import SurveyCompanyEdit from './SurveyCompanyEdit';
import SurveyCompanyList from './SurveyCompanyList';
import SurveyCompanyShow from './SurveyCompanyShow';
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: SurveyCompanyList,
  edit: SurveyCompanyEdit,
  create: SurveyCompanyCreate,
  show: SurveyCompanyShow,
  icon: SurveyCompanyIcon,
};

import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import { makeStyles } from '@material-ui/core/styles';

import Logo from './Logo';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
});

// eslint-disable-next-line react/display-name
const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate();

  return <MenuItemLink ref={ref} leftIcon={<SettingsIcon />} placeholder="" primaryText={translate('pos.configuration')} to="/configuration" onClick={props.onClick} onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}} />;
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ConfigurationMenu />
  </UserMenu>
);

const CustomAppBar = (props: any) => {
  const classes = useStyles();

  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography className={classes.title} color="inherit" id="react-admin-title" variant="h6" />
      <Logo />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default CustomAppBar;

import * as React from 'react';
import { InputGuesser, CreateGuesser } from '@api-platform/admin';

const CategoryCreate = (props: any) => (
  <CreateGuesser {...props}>
    <InputGuesser source="title" />
    <InputGuesser source="description" />
    <InputGuesser source="isActive" />
  </CreateGuesser>
);

export default CategoryCreate;

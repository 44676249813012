import * as React from 'react';
import { FieldGuesser } from '@api-platform/admin';
import { Datagrid, EditButton, Filter, List, SearchInput, ShowButton, useListContext } from 'react-admin';
import { Fragment } from 'react';
import { BulkDeleteButton } from 'react-admin';

import { Category } from '../interfaces/category';
import ColoredBooleanFields from '../common/ColoredBooleanFields';

const CategoryFilters = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Title" source="title" alwaysOn resettable />
  </Filter>
);

type CategoryWithId = Category & { id: string };

const PostBulkActionButtons = () => {
  const { selectedIds } = useListContext<CategoryWithId>();
  const categoryId = selectedIds;
  const [hasRecords, setHasRecords] = React.useState(false);
  const SERVERURL = process.env.REACT_APP_API_ENTRYPOINT;

  React.useEffect(() => {
    let hasAssociatedSurvey = false;

    const checkAssociation = async (categoryId: any) => {
      const response = await fetch(`${SERVERURL}/survey_form_question?categoryId=${categoryId}`);
      const responseData = await response.json();

      if (responseData.RecordExists) {
        hasAssociatedSurvey = true;
      }
    };

    Promise.all(selectedIds.map(checkAssociation))
      .then(() => {
        setHasRecords(hasAssociatedSurvey);
      })
      .catch((error) => {
        console.error('Error checking category id', error);
      });
  }, [categoryId]);

  return (
    <Fragment>
      <BulkDeleteButton disabled={hasRecords} />
    </Fragment>
  );
};

const CategoryList = (props: Category) => {
  return (
    <List bulkActionButtons={<PostBulkActionButtons />} filters={<CategoryFilters />} {...props} exporter={false} sort={{ field: 'createdAt', order: 'DESC' }}>
      <Datagrid>
        <FieldGuesser source="title" />
        <ColoredBooleanFields source="isActive" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default CategoryList;

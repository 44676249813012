import * as React from 'react';
import { Identifier, Datagrid, TextField, NumberField, BooleanField, RichTextField, DatagridProps } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import rowStyle from './rowStyle';

const useListStyles = makeStyles({
  headerRow: {
    borderLeftColor: 'transparent',
    borderLeftWidth: 5,
    borderLeftStyle: 'solid',
  },
  headerCell: {
    padding: '6px 8px 6px 8px',
  },
  rowCell: {
    padding: '6px 8px 6px 8px',
  },
  comment: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});
export type ReviewListDesktopProps = {
  selectedRow?: Identifier;
} & DatagridProps;

const SurveyCompanyQuestionsField = ({ selectedRow, ...props }: ReviewListDesktopProps) => {
  const classes = useListStyles();

  return (
    <Datagrid
      classes={{
        headerRow: classes.headerRow,
        headerCell: classes.headerCell,
        rowCell: classes.rowCell,
      }}
      // @ts-ignore
      rowStyle={rowStyle(selectedRow)}
      optimized
      {...props}>
      <TextField label="Title" source="questionTitle" />
      <NumberField label="Rank" source="rank" />
      <BooleanField label="Required?" source="isRequired" />
      <BooleanField label="Visible?" source="isVisible" />
      <NumberField label="Max Selection" source="maxSelection" />
      <TextField label="Type Restriction" source="typeRestriction" />
      <RichTextField source="info" />
    </Datagrid>
  );
};

SurveyCompanyQuestionsField.defaultProps = {
  source: 'surveyCompanySurveyFormQuestions',
  resource: 'survey_companies',
};

export { SurveyCompanyQuestionsField };

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';

const TokenList = (props: any) => (
  <ListGuesser {...props} exporter={false}>
    <FieldGuesser source="name" />
    <FieldGuesser source="description" />
  </ListGuesser>
);

export default TokenList;

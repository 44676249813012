import * as React from 'react';
import { ShowGuesser } from '@api-platform/admin';
import { ReferenceField, TextField, ReferenceArrayField, SingleFieldList, ChipField, Datagrid, ArrayField, useRecordContext, SimpleShowLayout, DateField, BooleanField } from 'react-admin';

import { SurveyCompany } from '../interfaces/surveycompany';

import { SurveyCompanyQuestionsField } from './SurveyCompanyField';
const SurveyCompanyShowLayout = () => {
  const record = useRecordContext();

  if (!record) return null;

  const renderSurveyCompanyQuestionsField = () => {
    if (record.categories === null && record?.categories?.length === 0) return <React.Fragment />;

    return (
      <ArrayField source="categories">
        <Datagrid>
          <TextField label="Category" source="category.title" fullWidth />
          <ArrayField label="Questions" source="surveyCompanySurveyFormQuestions">
            <SurveyCompanyQuestionsField />
          </ArrayField>
        </Datagrid>
      </ArrayField>
    );
  };

  const renderDataGrid = () => {
    if (record.surveyCompanyTokens === null && record?.surveyCompanyTokens?.length === 0) return <React.Fragment />;

    return (
      <ArrayField source="surveyCompanyTokens">
        <Datagrid>
          <ReferenceField label="Tokens" reference="tokens" source="token">
            <ChipField source="name" />
          </ReferenceField>
          <TextField source="replaceValue" />
        </Datagrid>
      </ArrayField>
    );
  };

  return (
    <SimpleShowLayout>
      <TextField source="title" addLabel />
      <ReferenceField label="Company" reference="companies" source="company">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Deployment Type" reference="deployment_types" source="deploymentType">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceArrayField label="Categories" reference="survey_forms" source="surveyForms">
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      {renderDataGrid()}
      <DateField source="startDate" addLabel />
      <DateField source="endDate" addLabel />
      <DateField source="createdAt" addLabel />
      <BooleanField label="Is Active?" source="isActive" addLabel />
      {renderSurveyCompanyQuestionsField()}
    </SimpleShowLayout>
  );
};

const SurveyCompanyTitle = () => {
  const record = useRecordContext();

  if (!record) return null;

  return <span>Show Company/Generic Survey {record.title}</span>;
};

const SurveyCompanyShow = (props: SurveyCompany) => {
  return (
    <ShowGuesser mutationMode="pessimistic" title={<SurveyCompanyTitle />} hasList {...props}>
      <SurveyCompanyShowLayout />
    </ShowGuesser>
  );
};

export default SurveyCompanyShow;

import * as React from 'react';
import { ListGuesser, FieldGuesser } from '@api-platform/admin';
//import { ReferenceField, TextField } from "react-admin";

const SurveyCompanyTokenList = (props: any) => (
  <ListGuesser {...props} exporter={false}>
    <FieldGuesser source="surveyCompany" />
    <FieldGuesser source="token" />
    <FieldGuesser source="replaceValue" />
  </ListGuesser>
);

export default SurveyCompanyTokenList;

import * as React from 'react';
import { InputGuesser, EditGuesser } from '@api-platform/admin';
import RichTextInput from 'ra-input-rich-text';
import { NumberInput, maxValue, minValue } from 'react-admin';

import { Recommendation } from '../interfaces/recommendation';

const validateLevel = [minValue(1), maxValue(5)];

const RecommendationEdit = (props: Recommendation) => (
  <EditGuesser {...props}>
    <InputGuesser source="name" />
    <RichTextInput source="description" />
    <NumberInput max={5} min={1} source="level" validate={validateLevel} />
  </EditGuesser>
);

export default RecommendationEdit;

import * as React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { AutocompleteInput, Filter, ReferenceField, ReferenceInput, SearchInput, TextField } from 'react-admin';

const SurveyContributionFilters = (props: any) => (
  <Filter {...props}>
    <SearchInput placeholder="Email" source="user.email" alwaysOn resettable />
    <ReferenceInput filterToQuery={(searchText) => ({ name: searchText })} label="Company" reference="companies" source="company" alwaysOn resettable>
      <AutocompleteInput />
    </ReferenceInput>
    <ReferenceInput filterToQuery={(searchText) => ({ campaignName: searchText })} label="Pinpoint Campaign" reference="pinpoint_campaigns" source="pinpointCampaign" allowEmpty>
      <AutocompleteInput optionText="campaignName" optionValue="@id" clearAlwaysVisible resettable />
    </ReferenceInput>
  </Filter>
);

const SurveyContributionList = (props: any) => (
  <ListGuesser filters={<SurveyContributionFilters />} {...props} exporter={false} sort={{ field: 'createdAt', order: 'DESC' }}>
    <ReferenceField label="user" link={false} reference="users" source="user">
      <TextField source="email" />
    </ReferenceField>
    <ReferenceField label="Pinpoint Campaign" link={false} reference="pinpoint_campaigns" sortBy="pinpointCampaign.campaignName" source="pinpointCampaign">
      <TextField source="campaignName" />
    </ReferenceField>
  </ListGuesser>
);

export default SurveyContributionList;

import { blue, green, orange, red } from '@material-ui/core/colors';

import { SurveyCompanySurveyFormQuestion } from '../interfaces/surveycompanysurveyformquestion';

const rowStyle = (record: SurveyCompanySurveyFormQuestion) => {
  const style = {};

  if (!record) {
    return style;
  }

  if (record.level === 0)
    return {
      ...style,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
      paddingLeft: 10,
    };

  if (record.level === 1)
    return {
      ...style,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
      paddingLeft: 10,
      marginLeft: 5,
    };

  if (record.level === 2)
    return {
      ...style,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
      paddingLeft: 10,
      marginLeft: 10,
    };

  if (record.level === 3)
    return {
      ...style,
      borderLeftColor: blue[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
      paddingLeft: 10,
      marginLeft: 15,
    };

  return { iteration: style };
};

export default rowStyle;

import * as React from 'react';
import { Layout, LayoutProps } from 'react-admin';

import AppBar from './AppBar';
import Menu from './Menu';
import { kanarysTheme } from './themes';

// eslint-disable-next-line import/no-anonymous-default-export, react/display-name
export default (props: LayoutProps) => {
  // const theme = useSelector((state: AppState) => (state.theme === "dark" ? darkTheme : lightTheme));
  return <Layout {...props} appBar={AppBar} menu={Menu} theme={kanarysTheme} />;
};

import * as React from 'react';
import { FieldGuesser, ShowGuesser } from '@api-platform/admin';

import { EmployeesRange } from '../interfaces/employeesrange';

const EmployeesRangeShow = (props: EmployeesRange) => (
  <ShowGuesser {...props}>
    <FieldGuesser source="name" addLabel />
    <FieldGuesser source="position" addLabel />
  </ShowGuesser>
);

export default EmployeesRangeShow;

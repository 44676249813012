import * as React from 'react';
import { ListGuesser } from '@api-platform/admin';
import { ReferenceField, TextField, FunctionField, ReferenceInput, AutocompleteInput, FormDataConsumer, SelectInput, SearchInput, Filter } from 'react-admin';

import { dateFormatter } from '../utils/commonUtils';
import ColoredBooleanFields from '../common/ColoredBooleanFields';
import CustomUrlField from '../common/CustomUrlField';

import ClearDataButton from './ClearDataButton';
const MemberDataFilters = (props: any) => (
  <Filter {...props}>
    <ReferenceInput filterToQuery={(searchText) => ({ name: searchText })} label="Company" reference="companies" source="user.company" alwaysOn resettable>
      <AutocompleteInput />
    </ReferenceInput>
    <FormDataConsumer source="pinpointCampaign" alwaysOn>
      {({ formData, ...rest }) =>
        formData && formData?.user?.company ? (
          <ReferenceInput filter={{ surveyCompany: { company: formData?.user?.company } }} filterToQuery={(searchText) => ({ campaignName: searchText })} label="Pinpoint Campaign" reference="pinpoint_campaigns" source="pinpointCampaign" allowEmpty {...rest}>
            <AutocompleteInput optionText="campaignName" optionValue="@id" clearAlwaysVisible resettable />
          </ReferenceInput>
        ) : null
      }
    </FormDataConsumer>
    <SelectInput
      choices={[
        { id: 1, name: 'Active' },
        { id: 0, name: 'InActive' },
      ]}
      label="User Status"
      source="user.enable"
    />
    <SearchInput placeholder="User Email" source="user.email" resettable />
  </Filter>
);

const PinpointCampaignUserList = (props: any) => {
  return (
    <ListGuesser filters={<MemberDataFilters />} {...props} bulkActionButtons={false} exporter={false} hasCreate={false} hasEdit={false} hasShow={false} hasList>
      <TextField label="Unique Id" source="user.uniqueId" />
      <TextField label="User Name" source="user.fullNameWithEmail" />
      <ColoredBooleanFields label="Status" source="user.enable" />
      <ReferenceField label="Pinpoint Campaign" link={false} reference="pinpoint_campaigns" sortBy="pinpointCampaign.campaignName" source="pinpointCampaign">
        <TextField source="campaignName" />
      </ReferenceField>
      <FunctionField label="Questions Attended" render={(ppuser: any) => `${ppuser.totalContriQues} of ${ppuser.totalQues} ${ppuser.userPinpointCampaignStatus} ${ppuser.userPinpointCampaignSubmittedAt ? dateFormatter(ppuser.userPinpointCampaignSubmittedAt) : ''}`} />
      <CustomUrlField label="Assessment Link" sortable={false} source="surveyUrl" target="_blank" />
      <ClearDataButton />
    </ListGuesser>
  );
};

export default PinpointCampaignUserList;
